import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Integrations} from "../../../../shared/models/integrations.model";
import {IntegrationsService} from "../../../../shared/services/integrations.service";
import {AuthenticationGuard} from "../../../../shared/guards/authentication.guard";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {BaseComponent} from "../../../../shared/components/base.component";
import {Integration} from "../../../../shared/enums/integration.enum";
import {IntegrationMetaData} from "../../../../shared/models/integration-meta-data.model";
import {DetailsComponent as ContextqaDetailsComponent} from "./details.component";

@Component({
  selector: 'app-contextqa',
  templateUrl: './create.component.html'
})
export class CreateComponent extends BaseComponent implements OnInit {
  updateForm: FormGroup;
  plug: Integrations = new Integrations();
  saving = false;
  constructor(
    private integrationsService: IntegrationsService,
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private router: Router,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<CreateComponent>,
    @Inject(MAT_DIALOG_DATA) public options: { workspaceId: number, name: string},
  ) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  ngOnInit(): void {
    this.plug.workspaceId= this.options.workspaceId;
    console.log("workspaceId ::", this.plug.workspaceId)
    this.plug.name=this.options.name;
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.updateForm = new FormGroup({
      // 'url': new FormControl(null,  [Validators.required, Validators.pattern(reg)]),
      // 'token':new ForsmControl(null, Validators.required),
      'userName':new FormControl(null, Validators.required),
      // 'confirmToken': new FormControl(null, Validators.required)
    })
  }

  onSubmit(){
    this.saving = true;
    const integration = new Integrations();
    integration.name = "ContextQaPlugin";
    integration.workspaceId = 17;
    integration.workspace = Integration.ContextQaPlugin;
    integration.metaData = new IntegrationMetaData();
    integration.metaData.user_name = this.updateForm.value.userName;

    this.integrationsService.contextqaPluginsIntegration(integration).subscribe({
      next: (data: any) => {
        console.log("data ::", data)
        this.saving = false;
        this.translate.get('message.common.plugin_integration_configuration.success', {FieldName: "ContextQA"}).subscribe((res) => {
          this.showNotification(NotificationType.Success, res);
          this.dialogRef.close(data);
          if(data.name == "ContextQaPlugin"){
            console.log("Opening details with data:", data);
            setTimeout(() => {
              this.matDialog.open<BaseComponent>(this.getViewComponent(), {
                height: '600px',
                width: '50%',
                data: {
                  workspaceId: data.id,
                  name: Integration.ContextQaPlugin
                },
                panelClass: ['mat-dialog', 'rds-none']
              });
            }, 100);
          }
        });
      },
      error: (error) => {
        this.saving = false;
        this.translate.get("message.common.plugin_integration_test.failure", {FieldName: "ContextQA"}).subscribe((res: string) => {
          this.showAPIError(error, res);
        });
      }
    });
  }

  getViewComponent() {
    return ContextqaDetailsComponent;
  }
}

