import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SessionService} from "../shared/services/session.service";
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationConfigService} from "../services/authentication-config.service";
import {AuthenticationConfig} from "../models/authentication-config.model";
import {AuthenticationType} from "../shared/enums/authentication-type.enum";
///<reference types="chrome"/>

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ["./login-form.component.scss"]
})
export class LoginFormComponent implements OnInit {
  public token: string;
  public loginForm: FormGroup;
  public authConfig: AuthenticationConfig;
  public isGoogleAuth: boolean;
  public error: String;
  public origin: any;
  @ViewChild('loginFormElm') loginFormElm: ElementRef<HTMLFormElement>;
  get inTransit(): boolean {
    return this.loginForm?.disabled;
  }

  constructor(
    private router: Router,
    private authConfigService: AuthenticationConfigService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.origin = window.location.hostname.split('.')[0];
    this.loginForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl(false)
    })
    // this.fetchAuthConfig();
    this.route.queryParams.subscribe(params => {
      if(params.token){
        this.sessionService.setToken( params.token, true);
        this.router.navigate(['dashboard']);
        const sendTokenToChromeExtension = ({ extensionId, jwt}: any) => {
          chrome.runtime.sendMessage(extensionId, { jwt, origin: this.origin  }, (response: any): any => {
            if (!response.success) {
              return response;
            }
          });
        }
        // let data = {
        //   jwt: params.token,
        //   origin : window.location.hostname.split('.')[0],
        //   userEmail: localStorage.getItem('useremail')
        // };
        // chrome.runtime.sendMessage('pgadpooodaipbhbchnojijmlkhpamadh', { message: "getStatus", data: data })
        // sendTokenToChromeExtension({ extensionId: 'pgadpooodaipbhbchnojijmlkhpamadh', jwt: params.token, })
      }
      // else if(this.origin && this.origin.length){
      //   window.location.href = 'https://accounts.contextqa.com/login?org='+this.origin
      // }
      else{
        this.sessionService.logout();
        if(this.origin && this.origin.length){
          window.location.href = 'https://accounts.contextqa.com/login?org='+this.origin
        }else{
          this.router.navigate(['login'])
        }
        
      }
    })
  }

  get credRequired(): boolean {
    return this.route.snapshot.queryParams.error == 'required';
  }

  get badCredentials(): boolean {
    return this.route.snapshot.queryParams.error == 'bad_cred';
  }

  get invalidAuthentication(): boolean{
    return this.route.snapshot.queryParams.error == 'bad_auth';
  }

  get notActivated(): boolean{
    return this.route.snapshot.queryParams.error == 'not_activated';
  }


  login() {
    //this.loginFormElm.nativeElement.submit();
    const pathname = window.location.hostname.split('.');
    if(pathname && pathname.length){
      window.location.href = 'https://accounts.contextqa.com/login?org='+pathname[0]
    }else {
      return false;
    }
    // if (this.loginForm.valid) {
    //   const { username, password, remember } = this.loginForm.value;
    //   this.loginForm.disable();
    //   this.sessionService.login(username, password, remember).subscribe(() => {
    //     this.router.navigate(['dashboard']);
    //   }, err => {
    //     console.warn(err);
    //     this.loginForm.enable();
    //     this.error = err.error?.error || '';
    //     if(this.error.includes("user name and password is disabled") || this.error.includes("Problem in configured authentication provider")){
    //       this.router.navigate(['login'],{queryParams: {error:'bad_auth'}});
    //     } else if(this.error.includes("not activated")){
    //       this.router.navigate(['login'],{queryParams: {error:'not_activated'}})
    //     } else{
    //       this.router.navigate(['login'],{queryParams: {error:'bad_cred'}});
    //     }
    //   });
    // } else {
    //   this.router.navigate(['login'],{queryParams: {error:'required'}});
    //   this.loginForm.markAsTouched();
    // }
  }

  fetchAuthConfig()
  {
    this.authConfigService.find().subscribe(
      (authConfig) => {
        this.authConfig = authConfig;
        this.isGoogleAuth = authConfig.authenticationType == AuthenticationType.GOOGLE;
      });
  }
}
