import {Component, Inject, OnInit} from '@angular/core';
import {BaseComponent} from "../../../../shared/components/base.component";
import {Integrations} from "../../../../shared/models/integrations.model";
import {IntegrationsService} from "../../../../shared/services/integrations.service";
import {AuthenticationGuard} from "../../../../shared/guards/authentication.guard";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationModalComponent} from "../../../../shared/components/webcomponents/confirmation-modal.component";
import { Clipboard } from '@angular/cdk/clipboard';
import { FormGroup, FormControl } from '@angular/forms';
import { VerifyResponse } from '../../../../shared/models/verify-response.model';

@Component({
  selector: 'app-view',
  templateUrl: './details.component.html',
  styles: [`
    .code-block {
      background: #1e293b;
      color: #e2e8f0;
      padding: 15px;
      border-radius: 6px;
      font-family: monospace;
      white-space: pre-wrap;
      margin: 10px 0;
    }
    
    .copy-button {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #6366f1;
    }

    .alert-box {
      border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    background-color: #FFEBEE;
    border: 1px solid #F44336;
    color: #F44336;
    }

    .alert-icon {
      color: #F44336;
    }

    .alert-box p {
      margin: 0;
    }

    .success-box {
      border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    background-color: #E8F5E9;
    border: 1px solid #4CAF50;
    color: #4CAF50;
    }

    .success-icon {
      color: #4CAF50;
    }

    .success-box p {
      margin: 0;
    }
  `]
})
export class DetailsComponent extends BaseComponent implements OnInit  {
  plug: Integrations ;
  workspaceId: number;
  saving = false;
  sending=false;
  updateForm: FormGroup;
  VerifyResponse: VerifyResponse;

  constructor(
    private integrationsService: IntegrationsService,
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private router: Router,
    private dialogRef: MatDialogRef<DetailsComponent>,
    private matModal: MatDialog,
    @Inject(MAT_DIALOG_DATA) public options: { workspaceId: number}, private clipboard: Clipboard) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  ngOnInit(): void {
    this.workspaceId=this.options.workspaceId;
    this.updateForm = new FormGroup({
      userName: new FormControl(null)
    });
    this.fetchPlugin();
  }

  fetchPlugin(){
    console.log("Fetching plugin with workspaceId:", this.workspaceId);
    this.integrationsService.find(this.workspaceId).subscribe(
      data => {
        console.log("Fetched plugin data:", data);
        this.plug = data;
      },
      error => { 
        console.error("Error fetching plugin:", error);
      }
    );
  }

  delete(){
    this.translate.get("message.common.confirmation.message", {FieldName: 'ContextQA Integration'}).subscribe((res) => {
      const dialogRef = this.matModal.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          description: res
        },
        panelClass: ['matDialog', 'delete-confirm']
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.destroy()
        }
      });
    })
  }

  Verify(){
    this.saving = true;
    this.integrationsService.verifyContextqa().subscribe({
      next: (response: VerifyResponse) => {
        console.log('Verification response:', response);
        this.VerifyResponse = response;
        if(response.install == true){
          this.saving = false;
          this.dialogRef.close(true);
          this.translate.get("message.common.contextqa_plugin_verify.success", {FieldName: 'ContextQA'}).subscribe((res: string) => {
            this.showNotification(NotificationType.Success, res);
          });
        } else{
          this.saving = false;
          this.translate.get("message.common.contextqa_plugin_verify.failure", {FieldName: 'ContextQA'}).subscribe((res: string) => {
            this.showNotification(NotificationType.Error, res);
          });
        }
      },
      error: (error) => {
        this.saving = false;
        this.translate.get("message.common.contextqa_plugin_verify.failure", {FieldName: 'ContextQA'}).subscribe((res: string) => {
          this.showAPIError(error, res);
        });
      }
    });
  }

  destroy(){
    this.saving = true;
    this.integrationsService.delete(this.workspaceId).subscribe({
        next: () => {
          this.saving = false;
          this.dialogRef.close(true);
          this.translate.get("message.common.plugin_integration.deleted.success", {FieldName: 'ContextQA'}).subscribe((res: string) => {
            this.showNotification(NotificationType.Success, res);
          });
        },
        error: (error) => {
          this.saving = false;
          this.translate.get("message.common.plugin_integration.deleted.failure", {FieldName: 'ContextQA'}).subscribe((res: string) => {
            this.showAPIError(error, res);
          });
        }
      }
    );
  }

  copyCode() {
    // const userName = this.updateForm.get('userName').value;
    const scriptCode = `
<script>
!function(t,e){var o,n,p,r;e.__SV||(window.contextqa=e,e._i=[],e.init=function(i){
    window.cqakey = i;
    const script = document.createElement('script');
    script.src = "https://cqa-media.s3.us-east-2.amazonaws.com/contextqa_init.js";
    document.head.appendChild(script);
},e.__SV=1)}(document,window.contextqa||[]);

// Initialize after defining
window.contextqa.init('${this.plug?.password}');
</script>`;
    
    this.clipboard.copy(scriptCode);
    this.showNotification(NotificationType.Success, 'Code copied to clipboard!');
  }

  getScriptContent() {
    return `<script>
!function(t,e){var o,n,p,r;e.__SV||(window.contextqa=e,e._i=[],e.init=function(i){
    window.cqakey = i;
    const script = document.createElement('script');
    script.src = "https://cqa-media.s3.us-east-2.amazonaws.com/contextqa_init.js";
    document.head.appendChild(script);
},e.__SV=1)}(document,window.contextqa||[]);

// Initialize after defining
window.contextqa.init('${this.plug?.password}');
</script>`;
  }

}

