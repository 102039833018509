<div class="overlay" [class.open]="isOpen" (click)="close()"></div>

<div class="sidebar" [class.open]="isOpen">
  <span class="close-icon" (click)="close()">&#10005;</span>

  <div class="container">
    <!-- Section 1: Image -->
    <div class="section image-section">
      <h3>
        Test Case Suggestion
        <a class="normal-text fs ml-3" (click)="refreshData(testcaseId, '')"
          ><i class="fa-refresh-thick" [matTooltip]="'Refresh Session'"></i
        ></a>
      </h3>
      <div class="content-wrapper">
        <div class="image-box" *ngIf="!testcasesuggestion?.img">
          <p>Image</p>
        </div>
        <img
          [src]="testcasesuggestion?.img"
          class="pointer"
          (click)="openScreenShort(testcasesuggestion?.img)"
        />
      </div>
      <!-- Section 2: Actions -->
      <div class="section actions-section">
        <h3>
          Available Actions
          <a
            class="normal-text fs ml-3"
            (click)="refreshData(testcaseId, 'session')"
            ><i class="fa-refresh-thick" [matTooltip]="'Refresh Action'"></i
          ></a>
        </h3>
        <div class="content-wrapper">
          <div class="folder">
            <div class="test-cases-container list-container custom-scrollbar">
              <div *ngIf="errorMessage" class="errormsg">
                {{ errorMessage }}
              </div>
              <div *ngIf="testcaseDetails">
                <div
                  *ngFor="
                    let section of objectKeys(testcaseDetails);
                    let sectionIndex = index
                  "
                >
                  <a
                    class="list-view green-highlight pl-5 text-t-secondary align-items-center min-h-20"
                    style="padding: 2px"
                  >
                    <div class="d-flex align-items-center cursor-pointer">
                      <mat-icon
                        class="folder-icon"
                        (click)="toggleExpand(sectionIndex)"
                      >
                        {{
                          expandedIndexes[sectionIndex]
                            ? "expand_more"
                            : "chevron_right"
                        }}
                      </mat-icon>

                      <mat-icon class="folder-icon">
                        {{
                          expandedIndexes[sectionIndex]
                            ? "folder_open"
                            : "folder"
                        }}
                      </mat-icon>

                      <div class="section-title">
                        <span
                          [textContent]="testcaseDetails[section]?.title"
                        ></span>
                      </div>
                    </div>
                    <div class="folder-right">
                      <button
                        mat-icon-button
                        class="add-btn"
                        [disabled]="!testcaseDetails[section]?.steps?.length"
                      >
                        <mat-icon (click)="addAllSteps(section)"
                          >add_circle</mat-icon
                        >
                      </button>
                    </div>
                  </a>

                  <div
                    *ngIf="expandedIndexes[sectionIndex]"
                    class="section-content"
                  >
                    <div
                      *ngFor="let testCase of testcaseDetails[section]?.steps"
                      class="test-case-item"
                    >
                      <a
                        class="list-view green-highlight pl-5 text-t-secondary align-items-center min-h-20"
                      >
                        <mat-icon class="file-icon pr-4">description</mat-icon>
                        <span
                          [textContent]="testCase?.name"
                          class="pl-5"
                        ></span>
                        <div class="folder-right">
                          <button
                            mat-icon-button
                            class="add-btn"
                            [disabled]="isInNewSteps(testCase)"
                          >
                            <mat-icon (click)="addNewStep(testCase)"
                              >add_circle</mat-icon
                            >
                          </button>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section 3: Generate Test Case -->
    <div class="section generate-section">
      <h3>Configure New Test Case</h3>

      <div class="content-wrapper">
        <div class="ai-prompt-container">
          <textarea
            class="ai-prompt-textarea"
            placeholder="Describe the test case you want to generate..."
            rows="4"
            [(ngModel)]="query"
          ></textarea>
          <div class="ai-prompt-footer">
            <div class="case-type-selector">
              <div [matMenuTriggerFor]="animals" class="case-type-trigger">
                <mat-icon>label</mat-icon>
                <span>Case Type </span>
                <span *ngIf="selectedCaseType">: {{ selectedCaseType }}</span>

                <mat-icon>arrow_drop_down</mat-icon>
              </div>
              <mat-menu #animals="matMenu" class="run-history-more-option">
                <button mat-menu-item (click)="selectCaseType('General')">
                  <mat-icon>check_circle_outline</mat-icon>
                  <span>General</span>
                </button>
                <button mat-menu-item (click)="selectCaseType('Positive')">
                  <mat-icon>add_circle_outline</mat-icon>
                  <span>Positive</span>
                </button>
                <button mat-menu-item (click)="selectCaseType('Negative')">
                  <mat-icon>remove_circle_outline</mat-icon>
                  <span>Negative</span>
                </button>
                <button mat-menu-item (click)="selectCaseType('Edge')">
                  <mat-icon>extension</mat-icon>
                  <span>Edge</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <h4>Elements</h4>

          <div class="selected-steps">
            <!-- Empty state message -->
            <div class="empty-state" *ngIf="!hasSelectedSteps()">
              <mat-icon>playlist_add</mat-icon>
              <p>No steps selected yet</p>
              <span>Please select steps from the available actions</span>
            </div>

            <!-- Selected steps list -->

            <div class="steps-list" *ngIf="hasSelectedSteps()">
              <div
                cdkDropList
                [cdkDropListData]="groupedSelectedSteps"
                (cdkDropListDropped)="drop($event)"
                class="step-list-container"
              >
                <div
                  *ngFor="let step of groupedSelectedSteps"
                  class="step-item"
                  cdkDrag
                >
                  <a class="list-view green-highlight align-items-center">
                    <div class="drag-handle" cdkDragHandle>
                      <mat-icon>drag_indicator</mat-icon>
                    </div>
                    <mat-icon class="step-icon">description</mat-icon>
                    <span>{{ step.name }}</span>
                    <button
                      mat-icon-button
                      class="remove-btn"
                      (click)="removeStepByName(step.name)"
                    >
                      <mat-icon>do_not_disturb_on</mat-icon>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <button
            [disabled]="loading || !hasSelectedSteps()"
            class="generate-prompt-btn w-100 justify-content-center"
            (click)="generateTestCase()"
          >
            <mat-icon>smart_toy</mat-icon>
            <span *ngIf="!loading">Generate</span>
            <span *ngIf="loading">Generating...</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Section 4: Preview -->
    <div class="section preview-section generate-section">
      <h3>Test Case Preview</h3>
      <div class="content-wrapper">
        <div class="selected-steps">
          <div class="empty-state" *ngIf="!previewTestcaseSuggestion">
            <mat-icon>playlist_add</mat-icon>
            <p>No Test case Preview</p>
          </div>
          <div class="steps-list" *ngIf="hasSelectedSteps()">
            <div
              *ngFor="
                let section of previewTestcaseSuggestion | keyvalue;
                let i = index
              "
              class="mb-10"
            >
              <div class="section-header">
                <mat-icon>folder</mat-icon>
                <h4>{{ section.value.title }}</h4>
              </div>
              <div
                cdkDropList
                [cdkDropListData]="section.value.steps"
                (cdkDropListDropped)="drop($event)"
                class="step-list-container"
              >
                <div
                  *ngFor="let step of section.value.steps"
                  class="step-item"
                  cdkDrag
                >
                  <a class="list-view green-highlight align-items-center">
                    <div class="drag-handle" cdkDragHandle>
                      <mat-icon>drag_indicator</mat-icon>
                    </div>
                    <mat-icon class="step-icon">description</mat-icon>

                    <!-- Editable step name -->
                    <input
                      class="step-name-edit border-0 w-100"
                      [readonly]="!isEditable"
                      placeholder="Edit step name"
                      (input)="onInputChange($event, step)"
                      [value]="
                        step.action +
                        (step.value && step.value.length
                          ? ' as value ' + step.value.join(', ')
                          : '')
                      "
                    />

                    <button
                      mat-icon-button
                      class="remove-btn ml-auto"
                      (click)="removeStepByNamePreview(step.name)"
                    >
                      <mat-icon>do_not_disturb_on</mat-icon>
                    </button>
                  </a>
                </div>
                <div
                  class="mb-10"
                  style="
                    display: flex;
                    justify-content: end;
                    text-align: center;
                    align-items: center;
                    gap: 10px;
                  "
                >
                  <button
                    class="add-btn-step"
                    (click)="addBlankStep(section.key)"
                  >
                    Add Step
                  </button>

                  <button
                    *ngIf="!loadingStates[section.key]"
                    class="add-btn-step"
                    (click)="createTestcase(section)"
                  >
                    Create Test Case
                  </button>
                  <button
                    *ngIf="loadingStates[section.key]"
                    class="add-btn-step"
                  >
                    Creating...
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="generate-prompt-btn w-100 justify-content-center"
          *ngIf="hasSelectedSteps() && previewTestcaseSuggestion"
          (click)="createTestCaseall()"
        >
          <span *ngIf="!loading"> Generate Test Case </span>
          <span *ngIf="loading"> Generating...</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loadingSpinner" class="loading-overlay-custom">
  <mat-progress-spinner
    class="search-spinner"
    mode="indeterminate"
    diameter="40"
  ></mat-progress-spinner>
</div>
