import { Injectable } from '@angular/core';
import { HttpHeadersService } from "./http-headers.service";
import { UrlConstantsService } from "./url.constants.service";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Element } from "../../models/element.model";
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { Pageable } from "../models/pageable";
import { Page } from "../models/page";
import { FilterableDataSourceService } from "./filterable-data-source.service";
import { ElementScreenName } from "../../models/element-screen-name.model";

@Injectable({
  providedIn: 'root'
})
export class ElementScreenNameService {
  constructor(
    private http: HttpClient,
    private httpHeaders: HttpHeadersService,
    private URLConstants: UrlConstantsService) {
  }
  public findAll(filter?: string, sortBy?: string, pageable?: Pageable): Observable<Page<ElementScreenName>> {
    return this.http.get<Page<ElementScreenName>>(this.URLConstants.elementScreenNameURL, {
      headers: this.httpHeaders.contentTypeApplication,
      params: this.httpHeaders.serializeParams(filter, sortBy, pageable)
    }).pipe(
      map(data => new Page<ElementScreenName>().deserialize(data, ElementScreenName)),
      catchError(() => throwError('Problem while fetching Elements'))
    )
  }

  public create(element: ElementScreenName): Observable<ElementScreenName> {
    return this.http.post<ElementScreenName>(this.URLConstants.elementScreenNameURL, element.serialize(), { headers: this.httpHeaders.contentTypeApplication }).pipe(
      map(data => new ElementScreenName().deserialize(data)),
      catchError((error) => throwError(error))
    );
  }
  public update(elementId: number, element: ElementScreenName): Observable<ElementScreenName> {
    const url = `${this.URLConstants.elementScreenNameURL}/${elementId}`; // Construct the PUT URL
    return this.http.put<ElementScreenName>(url, element.serialize(), { headers: this.httpHeaders.contentTypeApplication }).pipe(
      map(data => new ElementScreenName().deserialize(data)), // Map the response to the ElementScreenName model
      catchError((error) => throwError(error)) // Handle errors
    );
  }


  /**
   * Fetches GAPS data by URL
   * @param url The URL to fetch GAPS data for
   */
  public getGapsByUrl(url: string): Observable<any> {
    const encodedUrl = encodeURIComponent(url);
    return this.http.get(`${this.URLConstants.elementScreenNameURL}/by_url`, {
      headers: this.httpHeaders.contentTypeApplication,
      params: new HttpParams().set('url', url)
    }).pipe(
      catchError((error) => throwError(error))
    );
  }

  /**
   * Refreshes GAPS data by pulling latest information
   * @param url The URL to refresh GAPS data for
   */
  public refreshGapsData(url: string): Observable<any> {
    return this.http.post(`${this.URLConstants.elementScreenNameURL}/pull_data`,
      { url: url },
      { headers: this.httpHeaders.contentTypeApplication }
    ).pipe(
      catchError((error) => throwError(error))
    );
  }
  public refreshActiviteData(url: string, dateRange: string): Observable<any> {
    return this.http.post(`${this.URLConstants.elementScreenNameURL}/refresh_activity`,
      { url: url, date: dateRange },
      { headers: this.httpHeaders.contentTypeApplication }
    ).pipe(
      catchError((error) => throwError(error))
    );
  }
  /**
   * Fetches activity data for a given URL and date range
   * @param url The URL to fetch activity data for
   * @param dateRange The date range in format "YYYY/MM/DD-YYYY/MM/DD"
   */
  public getActivityData(url: string, dateRange: string): Observable<any> {
    return this.http.post(`${this.URLConstants.elementScreenNameURL}/pull_activity`,
      {
        url: url,
        date: dateRange
      },
      { headers: this.httpHeaders.contentTypeApplication }
    ).pipe(
      catchError((error) => throwError(error))
    );
  }
  public getHeatmapData(url: string): Observable<any> {
    return this.http.post(`${this.URLConstants.elementScreenNameURL}/pull_heatmaps`,
      {
        url: url
      },
      { headers: this.httpHeaders.contentTypeApplication }
    ).pipe(
      catchError((error) => throwError(error))
    );
  }
}
