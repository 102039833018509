import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-ai-data-analyst',
  templateUrl: './ai-data-analyst.components.html',
  styleUrls: ['./ai-data-analyst.components.scss']
})
export class AiDataAnalystComponent implements OnInit, AfterViewInit {
  @ViewChild('workflowFrame') workflowFrame!: ElementRef<HTMLIFrameElement>;
  
  iframeUrl: SafeResourceUrl;
  isLoading = true;
  isFullscreen = false;
  isLoggedIn = false;
  loginUrl = 'https://app.camelai.com/log-in?next=/';

  constructor(private sanitizer: DomSanitizer) {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://camelai.com/');
  }

  ngOnInit(): void {
    this.initializeIframe();
  }

  private initializeIframe(): void {
    // Initialize iframe with default settings
    this.isLoading = true;
    // You might want to add any specific initialization logic here
  }

  refreshWorkflow(): void {
    this.isLoading = true;
    const iframe = this.workflowFrame.nativeElement;
    iframe.src = iframe.src;
  }

  toggleFullscreen(): void {
    this.isFullscreen = !this.isFullscreen;
    // Allow time for the transition
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  onIframeLoad(): void {
    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    // Empty implementation since we don't need it right now
  }

  redirectToLogin(): void {
    window.open(this.loginUrl, '_blank');
  }
}