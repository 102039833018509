import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TestPlan } from 'app/models/test-plan.model';
import { TestPlanResultService } from 'app/services/test-plan-result.service';
import { TestPlanService } from 'app/services/test-plan.service';
import { Page } from 'app/shared/models/page';
import { Pageable } from 'app/shared/models/pageable';
import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-run-result-bar-chart',
  templateUrl: './run-result-bar-chart.component.html',
  styleUrls: ['./run-result-bar-chart.component.scss']
})
export class RunResultBarChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective<'bar'> | undefined;
  public sortBy: string;
  public currentPage: Pageable;
  public testPlans: Page<TestPlan>;
  selectedValue: any = 'Select Test Plans';
  private keys: any;
  public barChartType = 'bar' as const;
  public barChartData: ChartData<'bar'>;
  runResultData: any[];
  public direction = ",desc";
  public sortedBy = "createdDate";
  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: false
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true
      },
      legend: {
        display: true,
        position: 'top'
      }
    },
    onClick: (evt, array: any) => {
      if (array[0]) {
        this.legendClick(array[0].index);
      }
    }
  };
  isLoadingGraph: boolean = false;
  currentDataStartIndex: number = 0;
  totalDataPoints: number = 0;
  readonly recordsPerPage: number = 20;

  constructor(
    private testPlanService: TestPlanService,
    private testPlanResultService: TestPlanResultService,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.translate.get([
      'execution.result.SUCCESS', 'execution.result.FAILURE', 'execution.result.ABORTED',
      'execution.result.NOT_EXECUTED', 'execution.result.QUEUED',
      'execution.result.STOPPED']).subscribe((keys: any) => {
        this.keys = keys;
      });
    this.fetchTestPlans();
  }

  fetchTestPlans(query?: string) {
    query = query ? query : '';
    query = "workspaceVersionId:" + 1 + query + ",hideInDashboard:" + false;
    this.sortBy = this.sortedBy;
    this.testPlanService.findAll(query, this.sortBy, this.currentPage).subscribe(executions => {
      this.testPlans = executions;
      if (this.testPlans.content.length > 0) {
        this.selectedValue = this.testPlans.content[0];
        this.populateChartOptions('');
      }
    });
  }

  onSelectChange(event) {
    this.resetDataAndNavigation();
    this.populateChartOptions('');
  }

  resetDataAndNavigation() {
    this.currentDataStartIndex = 0;
    this.runResultData = [];
    this.totalDataPoints = 0;
  }

  populateChartOptions(query) {
    this.isLoadingGraph = true;
    query += "reRunParentId:null,testPlanId:" + this.selectedValue.id;
    this.sortBy = this.sortedBy;
    let pageable = new Pageable();
    pageable.pageSize = 300;
    pageable.pageNumber = 0;
    this.testPlanResultService.findAll(query, this.sortBy, pageable).subscribe((res) => {
      // Sort from newest to oldest
      this.runResultData = res.content.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      this.totalDataPoints = this.runResultData.length;
      // Set the start index to show the most recent data
      this.currentDataStartIndex = 0;
      this.updateChartData();
      this.isLoadingGraph = false;
    });
  }

  updateChartData() {
    const startIndex = this.currentDataStartIndex;
    const endIndex = Math.min(startIndex + this.recordsPerPage, this.totalDataPoints);
    const chartData = this.runResultData.slice(startIndex, endIndex);
    const labelArray = chartData.map(item => moment(item.createdAt).format('MMM D YYYY')).reverse();
    this.barChartData = {
      labels: labelArray,
      datasets: [
        this.createDataset('SUCCESS', 'passedCount', '#66ed66'),
        this.createDataset('FAILURE', 'failedCount', '#F23D3D'),
        this.createDataset('ABORTED', 'abortedCount', '#F0B14C'),
        this.createDataset('NOT_EXECUTED', 'notExecutedCount', '#7A68BC'),
        this.createDataset('QUEUED', 'queuedCount', '#3C8FE2'),
        this.createDataset('STOPPED', 'stoppedCount', '#C4C4C4')
      ]
    };

    if (this.chart) {
      this.chart.chart?.update();
    }
  }

  createDataset(key: string, countProperty: string, color: string) {
    return {
      label: this.keys[`execution.result.${key}`],
      data: this.runResultData.slice(this.currentDataStartIndex, this.currentDataStartIndex + this.recordsPerPage)
        .map((testPlanResult) => {
          if (countProperty === 'queuedCount' && testPlanResult.totalCount === 0 && testPlanResult.isQueued) {
            return 1;
          }
          if (countProperty === 'stoppedCount' && testPlanResult.totalCount === 0 && !testPlanResult.isQueued) {
            return 1;
          }
          return testPlanResult[countProperty];
        }).reverse(),
      backgroundColor: color,
      hoverBackgroundColor: color,
      barThickness: 25,
    };
  }

  showPreviousData() {
    if (this.currentDataStartIndex + this.recordsPerPage < this.totalDataPoints) {
      this.currentDataStartIndex += this.recordsPerPage;
      this.updateChartData();
    }
  }

  showNextData() {
    if (this.currentDataStartIndex > 0) {
      this.currentDataStartIndex = Math.max(0, this.currentDataStartIndex - this.recordsPerPage);
      this.updateChartData();
    }
  }

  legendClick(index: number) {
    const adjustedIndex = this.currentDataStartIndex + (this.recordsPerPage - 1 - index);
    const id = this.runResultData[adjustedIndex]?.id;
    if (id !== undefined) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/td/runs/${id}`]));
      window.open(url, '_blank');
    } else {
      console.error('ID not found for the given index');
    }
  }

  get canShowPrevious(): boolean {
    return this.currentDataStartIndex + this.recordsPerPage < this.totalDataPoints;
  }

  get canShowNext(): boolean {
    return this.currentDataStartIndex > 0;
  }
}