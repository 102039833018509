import { Component, Input } from "@angular/core";

@Component({
    selector: "app-common-button",
    template: `
    <span class="btn-group  ">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        class="border-rds-left-2  pl-10 pr-10 theme-btn-primary mr-0 fz-13"
        [ngClass]="customClass"
      >
        <span class="button-content d-flex align-items-center">
          <mat-icon class="fz-20">lock</mat-icon>
          <span class="label-text">{{ label }}</span>
        </span>
      </button>
      <mat-menu #menu="matMenu">
        <div class="dropdown-details  align-items-center">
          <p>To access this feature, please upgrade your plan.</p>
          <label class="upgrade" [routerLink]="['/pricing']">Upgrade</label>
        </div>
      </mat-menu>
    </span>
  `,
    styles: [
        `
      .lockDataBtnSidebar {
        background-color: transparent !important ;
        color: #4b5563;
        font-size: 13px;
      }
      .lockDataBtnSidebar .label-text {
        padding-left: 14px;
        font-weight: bold;
      }
      .TestCaseSuggestionBtn {
        color: #5e6c84 !important;
        font-size: 14px !important;
        font-weight: 600;
        background-color: transparent !important ;
      }
      .run-history-lock-btn {
        background: transparent;
      }
      .run-history-lock-btn:hover {
        background-color: black !important ;
      }
      .ai-log-btn {
        background: transparent;
      }
      .ai-log-btn:hover {
        background-color: #5b5b5b;
      }
      .schedule-lock-btn {
        background: transparent;
        border: 1px solid #b6cbd8;
      }
      .dropdown-details {
        padding: 10px;
      }
      button {
        line-height: 32px !important;
      }
      .upgrade {
        background-color: #6366f1;
        padding: 6px 12px 6px;
        border-radius: 3px;
        color: white;
        font-weight: 500;
        cursor: pointer;
        margin-left: auto;
        display: flex;
        width: max-content;
      }
      p {
        font-weight: 500;
        margin: 0px;
      }
    `,
    ],
})
export class CommonButtonComponent {
    @Input() label: string = "";
    @Input() customClass: string = "";
}
