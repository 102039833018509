import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TestCaseStatus } from 'app/enums/test-case-status.enum';
import { WorkspaceVersion } from 'app/models/workspace-version.model';
import { TestCaseService } from 'app/services/test-case.service';
import { UserPreferenceService } from 'app/services/user-preference.service';
import { WorkspaceVersionService } from 'app/shared/services/workspace-version.service';
import { MixpanelService } from '../../../mixpanel.service';
import { NgZone } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AiTestCaseService } from 'app/services/ai-test-case.service';
import { Page } from 'app/shared/models/page';
import { TestCase } from 'app/models/test-case.model';
import { ChromeExtensionService } from '../../../services/chrome.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-model-content',
  templateUrl: './model-content.component.html',
  styleUrls: ['./model-content.component.scss']
})
export class ModelContentComponent implements OnInit {

  public versionId: any;
  public version: WorkspaceVersion;
  public urlForm: FormGroup;
  public origin: any;
  public testCase: any = {};
  public testCaseList: Page<TestCase>;
  public testCaseId: number;
  public isFromAiChat: boolean = false;
  public cqaOrigin: string;
  

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  dialogReff!: MatDialogRef<any>;
  tabs: chrome.tabs.Tab[] = [];
  isExtensionInstalled: boolean;
  private extensionId = 'pgadpooodaipbhbchnojijmlkhpamadh';
  checkExtensionInstall: boolean = true;
  createdTestCaseData: any;
  openTabs: string[] = [];
  isLoading = false;
  testcaseListLength;
  public selectedTabUrl: string | null = null;
  filteredUrls: Observable<any[]>;
  predefinedUrls: any[] = [];
  isInputFocused = false;
  selectedOption: 'prebuilt' | 'AITestcase' | 'qa' | 'manuall' | null = null;
  public aiTestcaseForm: FormGroup;
  public autoCompleteActive = false;
  public selectedPrerequisite: any = null;
  @ViewChild('autoComplete') autoComplete: any;
  private dropdownVisible = false;
  public isDropdownOpen = false;
  public showWarning: boolean = true;
  isAIInputFocused = false;
  aiFilteredUrls: Observable<any[]>;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public userPreferenceService: UserPreferenceService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModelContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public testCaseService: TestCaseService,
    public translate: TranslateService,
    private mixpanelService: MixpanelService,
    private versionService: WorkspaceVersionService,
    private ngZone: NgZone,
    private aiTestCaseService: AiTestCaseService,
    private ChromeExtensionService: ChromeExtensionService,
  ) {
    this.checkExtension();
    this.urlForm = this.formBuilder.group({
      url: [''],
      preRequisite: ['']
    });
    this.aiTestcaseForm = this.formBuilder.group({
      preRequisite: [''],
      url: [''],
      description: ['']
    });

  }

  ngOnInit(): void {
    this.urlForm = this.formBuilder.group({
      url: ['', Validators.required],
      preRequisite: ['']

    });
    this.aiTestcaseForm = this.formBuilder.group({
      preRequisite: [''],
      url: ['', [Validators.required, this.urlValidator()]],
      description: ['', Validators.required]
    });
    
    this.origin = window.location.hostname.split('.')[0];
    this.fetchVersion();
    this.fetchTestcase();
    this.fetchTabs();

    // Add validator subscription
    this.aiTestcaseForm.get('preRequisite').valueChanges.subscribe(value => {
      const urlControl = this.aiTestcaseForm.get('url');
      
      if (!value || value === 'none') {
        urlControl.setValidators([Validators.required]);
      } else {
        urlControl.clearValidators();
      }
      
      urlControl.updateValueAndValidity();
    });

    // Restore saved form values if they exist
    const savedValues = sessionStorage.getItem('formValues');
    if (savedValues) {
      const values = JSON.parse(savedValues);
      this.urlForm.patchValue(values);
      
      // Optional: Clear storage after restoring
      sessionStorage.removeItem('formValues');
    }

    // Setup filter for AI testcase form
    this.aiFilteredUrls = this.aiTestcaseForm.get('url').valueChanges.pipe(
      startWith(''),
      map(value => this._filters(value))
    );
  }

  private initializeFilteredUrls() {
    console.log('Initializing filtered URLs with predefinedUrls:', this.predefinedUrls);
    this.filteredUrls = this.urlForm.get('url')!.valueChanges.pipe(
      startWith(''),
      map(value => {
        console.log('Filtering with value:', value);
        return this._filter(value);
      })
    );
  }

  private _filter(value: string): any[] {
    if (!value) {
      console.log('Empty value, returning all predefinedUrls:', this.predefinedUrls);
      return this.predefinedUrls;
    }
    
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    const filtered = this.predefinedUrls.filter(item => {
      const urlToMatch = item.url.toLowerCase();
      return urlToMatch.includes(filterValue);
    });
    console.log('Filtered URLs:', filtered);
    return filtered;
  }

  displayFn = (item: any): string => {
    if (!item) return '';
    return typeof item === 'string' ? item : item.url.replace(/^(https?:\/\/)/, '');
  }

  onOptionSelected(event: any) {
    if (!event?.option?.value) return;

    const selectedOption = event.option.value;
    console.log('Selected option:', selectedOption);

    // Get the URL without protocol
    const url = selectedOption.url.replace(/^(https?:\/\/)/, '');
    console.log('Setting URL to:', url);

    // Update form control
    this.ngZone.run(() => {
      this.urlForm.get('url')?.setValue(url);
      this.isInputFocused = false;
    });
  }

  fetchTabs() {  
    console.log("Fetching tabs...");
    
    try {
      if (typeof chrome !== 'undefined' && chrome?.runtime?.sendMessage) {
        chrome.runtime.sendMessage(
          this.extensionId,
          { event: 'getTabs' },
          (response) => {
            this.ngZone.run(() => {
              if (response && response.tabUrls) {
                this.tabs = response.tabUrls.map((url: string) => ({
                  url: url
                }));
                
                this.predefinedUrls = this.tabs.map(tab => ({
                  url: tab.url
                }));
                console.log('Predefined URLs updated:', this.predefinedUrls);
                
                this.initializeFilteredUrls();
              } else {
                console.log('No tabs response or empty tabUrls');
                this.setFallbackUrls();
              }
            });
          }
        );
      } else {
        console.log('Chrome runtime not available, using fallback data');
        this.setFallbackUrls();
      }
    } catch (error) {
      console.warn('Error fetching tabs:', error);
      this.setFallbackUrls();
    }
  }

  private setFallbackUrls() {
    this.predefinedUrls = [];
    console.log('Fallback URLs set:', this.predefinedUrls);
    this.initializeFilteredUrls();
  }

  fetchVersion() {
    this.userPreferenceService.show().subscribe(res => {
      this.versionId = res?.versionId;
      if (this.versionId) {
        this.versionService.show(this.versionId).subscribe(version => {
          this.version = version;
        });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  fetchTestcase(){
    const query = "deleted"
    const short = "createdDate,desc"
    const page = "undefined"
    this.testCaseService.findAll(query, short).subscribe((testcaseList) => {
      console.log("testcaseList ::", testcaseList)
      this.testcaseListLength = testcaseList.content.length
    })
  }

  getCurrentItem(items: Page<TestCase>, id: number) {
    if (!items?.content) return null;
    return items.content.find(item => item.id === id) || null;
  }
  
  fetchTestCases(term?) {
    let searchName = '';
    if (term) {
      searchName = ",name:*" + term + "*";
    }
    this.testCaseService.findAll("isStepGroup:false,workspaceVersionId:" + this.versionId + ",deleted:false" + searchName + ",status:" + TestCaseStatus.READY, undefined).subscribe(res => {
      res.content = res.content.filter(testcase => this.testCaseId != testcase.id)
      if(this.testCase?.id && this.testCase?.preRequisite)  {
        if(!res?.content?.find(req => req.id == this.testCase.preRequisite)) {
          res.content.push(this.testCase.preRequisiteCase);
        }
      }
      this.testCaseList = res;
    });
  }
  setPreRequisite(event: any) {
    if (event) {
      this.aiTestcaseForm.patchValue({
        preRequisite: event
      });
    } else {
      // Handle when none is selected
      this.aiTestcaseForm.patchValue({
        preRequisite: 'none'
      });
    }
  }

  listenForTabUpdates(): void {
    if (chrome && chrome.runtime) {
        chrome.runtime.onMessage.addListener((message, sender, sendResponse) => {
            this.ngZone.run(() => {
                if (message.event === 'tabsUpdated' && message.tabs) {
                    this.tabs = message.tabs.map((url: string, index: number) => ({
                        url,
                        // title: `Tab ${index + 1}` // Replace with actual title if available
                    }));
                    console.log('Real-time updated tabs:', this.tabs);
                }
            });
        });
    }
}
   // Update the URL field when a tab is selected
   onTabSelect(selectedUrl: string): void {
    this.urlForm.patchValue({ url: selectedUrl });
  }
  onSubmit() {
    if (this.urlForm.valid) {
      this.isLoading = true;
      const url = this.urlForm.get('url').value;
      const fullUrl = url.startsWith('https://') ? url : `https://${url}`;
      
      let testCase = {
        "isExtensionUsed": false,
        "name": formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US').toString() + "_test_case",
        "description": null,
        "status": TestCaseStatus.READY,
        "sendMailNotification": false,
        "isStepGroup": false,
        "priorityId": 1,
        "type": 1,
        "preRequisite": this.urlForm.value.preRequisite,
        "isDataDriven": false,
        "workspaceVersionId": this.versionId,
        "deleted": false,
        "testDataStartIndex": 0,
        "tags": []
      };
      let fieldName = 'Test Case';
      this.testCaseService.autocreate(testCase).subscribe(
        (testcase) => {
          this.mixpanelService.setUserProperties({"Number_of_test_cases" : this.testcaseListLength + 1});
          this.mixpanelService.track('Test Case Created', {
            "id": testcase.id,
            "title": testcase.name,
            "status": TestCaseStatus.READY,
            "method": "Recorder",
          });
          this.isLoading = false;
          if (chrome && chrome.runtime) {
            let data = {
              type: "test_case",
              id: testcase.id,
              result: testcase,
              action: "openSidePanelFromPortal",
              origin: window.location.hostname.split(".")[0],
              jwt: localStorage.getItem("_t"),
              userEmail: localStorage.getItem('useremail')
            };
            chrome.runtime.sendMessage(
              this.extensionId,
              { message: "openSidePanelFromPortal", data: data },
              (data: any) => {
              }
            );
            this.closeDialog();
            this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            window.open(fullUrl, '_blank');
          } else {
            this.closeDialog();
            this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            window.open(fullUrl, '_blank');
          }
        },
        error => {
          this.isLoading = false;
          this.translate.get('message.common.created.failure', { FieldName: fieldName }).subscribe((res) => {
            // Handle error display
          })
        }
      )
    }
  }

  async createTestCaseCreateData(aiTestCaseData) {
    console.log("aiTestCaseData ::", aiTestCaseData)
    let testCaseSteps = [];
    if(aiTestCaseData.url) {
      testCaseSteps.push({
        "actionName": "navigateToUrl",
        "naturalTextActionId": 425,
        "name": aiTestCaseData.url,
        "event": {
          "customEvent": "navigateToUrl",
          "href": aiTestCaseData.url
        },
        action: `Navigate to <span data-key=\"test-data\" data-event-key=\"href\" class=\"test_data action-flex-auto\" data-test-data-type=\"undefined\">${aiTestCaseData.url}</span>`,
        testData: aiTestCaseData.url,
        position: 0
      })
    } 
    if(aiTestCaseData.description) {
      console.log("aiTestCaseData.description ::", aiTestCaseData.description)
      testCaseSteps.push({
        action: `AI Agent <span data-key=\"element\" data-event-key=\"ai_task\" class=\"element action-flex-auto\">${aiTestCaseData.description}</span>`,
        actionName: "ai_text_actions",
        event: {
          customEvent: "ai_text_actions",
          ai_task: aiTestCaseData.description,
          description: aiTestCaseData.description,
          type: "create_step"
        },
        naturalTextActionId: 568,
        type: "ACTION_TEXT",
        disabled: false,
        ignoreStepResult: false,
        visualEnabled: false,
        position: 1
      })
    }

    return {
      "isExtensionUsed": false,
      "name": formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US').toString() + "_test_case",
      "description": null,
      "status": TestCaseStatus.READY,
      "sendMailNotification": false,
      "isStepGroup": false,
      "priorityId": 1,
      "type": 1,
      "preRequisite": aiTestCaseData.preRequisite.id,
      "isDataDriven": false,
      "workspaceVersionId": this.versionId,
      "deleted": false,
      "testDataStartIndex": 0,
      "tags": [],
      "steps": testCaseSteps
    };
  }

  async onSubmitAITestcase() {
    console.log('aiTestCase ::', this.aiTestcaseForm.value)
    if (this.aiTestcaseForm.valid) {
      this.isLoading = true;
      const url = this.aiTestcaseForm.get('url').value;
      const fullUrl = url.url.startsWith('https://') ? url.url : `https://${url.url}`;
      
      let aiTestCaseData = {
        "url": fullUrl,
        "description": this.aiTestcaseForm.get('description').value,
        "preRequisite": this.aiTestcaseForm.get('preRequisite').value
      }

      let testCase = await this.createTestCaseCreateData(aiTestCaseData);
      console.log("testCase ::", testCase)
      this.testCaseService.autocreate(testCase).subscribe(
        (testcase) => {
          console.log('testcase ::', testcase)
          if (testcase.id) {
            // need to dynamic
            this.cqaOrigin = window.location.hostname.split(".")[0];
            // this.cqaOrigin = 'vims'
            window.open(`https://${this.cqaOrigin}.contextqa.com/td/cases/${testcase.id}/steps?source=ai_chat`, '_blank');
            // this.createdTestCases.add(index);
          }
          this.closeDialog();
          this.isLoading = false;
        },
        error => {
          console.error('Error creating test case:', error);
          this.isLoading = false;
        }
      );
    }
  }

  selectOption(option: 'prebuilt' | 'AITestcase' | 'qa' | 'manuall') {
    this.selectedOption = option;
    // Add your logic here for handling the selection
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (this.autoComplete && !this.autoComplete.elementRef.nativeElement.contains(event.target)) {
      if (this.autoComplete.hideDropdown) {
        this.autoComplete.hideDropdown();
      }
      this.isDropdownOpen = false;
    }
  }

  // Add method to handle dropdown opening
  onDropdownOpen() {
    this.isDropdownOpen = true;
  }

  // Add method to handle dropdown closing
  onDropdownClose() {
    this.isDropdownOpen = false;
  }

  @ViewChild('autoComplete') set autoCompleteElement(element: any) {
    if (element) {
      this.autoComplete = element;
      // Add click listener to close dropdown when clicking outside
      document.addEventListener('click', (event: MouseEvent) => {
        if (!element.elementRef.nativeElement.contains(event.target)) {
          this.ngZone.run(() => {
            this.isDropdownOpen = false;
          });
        }
      });
    }
  }

  onBackClick(): void {
    // Store current form values before going back
    const currentValues = {
      url: this.urlForm.get('url')?.value,
      // ... store other form values if any
    };

    // Store in session/local storage if needed
    sessionStorage.setItem('formValues', JSON.stringify(currentValues));

    // Keep validation state
    if (this.urlForm.valid) {
      this.urlForm.markAsPristine(); // Prevents "unsaved changes" warnings if any
      this.urlForm.markAsTouched(); // Keeps validation visual state
    }

    // Navigate back while preserving form state
    this.router.navigate(['/td', 'cases']);
  }

  // Add this new method to handle going back
  goBack() {
    // Reset AI testcase form
    this.aiTestcaseForm.reset();
    this.aiTestcaseForm.patchValue({
      preRequisite: null,
      url: '',
      description: ''
    });
    
    // Reset URL form
    this.urlForm.reset();
    this.urlForm.patchValue({
      url: '',
      preRequisite: null,

    });

    // Reset selected option
    this.selectedOption = null;
    
    // Clear any validation errors
    Object.keys(this.aiTestcaseForm.controls).forEach(key => {
      const control = this.aiTestcaseForm.get(key);
      control.setErrors(null);
      control.updateValueAndValidity();
    });

    Object.keys(this.urlForm.controls).forEach(key => {
      const control = this.urlForm.get(key);
      control.setErrors(null);
      control.updateValueAndValidity();
    });
    this.isInputFocused = false;
  }
  async checkExtension() {
    this.isExtensionInstalled =
      await this.ChromeExtensionService.isExtensionInstalled(this.extensionId);
    if (this.isExtensionInstalled) {
      this.checkExtensionInstall = false;
    }else{
      this.checkExtensionInstall = true;
    }
  }
  // Add public getter
  public get getExtensionId(): string {
    return this.extensionId;
  }

  dismissWarning() {
    this.showWarning = false;
  }

  onAIOptionSelected(event: MatAutocompleteSelectedEvent) {
    // Handle AI form option selection
    const selectedOption = event.option.value;
    this.aiTestcaseForm.patchValue({
      url: selectedOption
    });
  }

  private _filters(value: string): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.predefinedUrls.filter(option => 
        option.url.toLowerCase().includes(filterValue));
    }
    return this.predefinedUrls;
  }

  onAIUrlBlur(event: any) {
    this.isAIInputFocused = false;
    const urlControl = this.aiTestcaseForm.get('url');
    if (urlControl) {
        let urlValue = urlControl.value;
        
        // If it's a string (direct input) rather than an object from autocomplete
        if (typeof urlValue === 'string') {
            // Remove any existing protocol
            urlValue = urlValue.replace(/^(https?:\/\/)/, '');
            
            // Update the form control value
            urlControl.setValue({
                url: urlValue,
                // Add any other required properties for your URL object
            });
        }
    }
  }

  private urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      
      try {
        // Prepend https:// if not present
        const urlString = control.value.url || control.value;
        const urlToTest = urlString.startsWith('http') ? urlString : `https://${urlString}`;
        new URL(urlToTest);
        return null;
      } catch (e) {
        return { invalidUrl: true };
      }
    };
  }
}