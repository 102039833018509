import { Component, Inject, Input, Output, EventEmitter, OnInit, SimpleChanges } from "@angular/core";
import { BaseComponent } from "app/shared/components/base.component";
import { trigger, transition, style, animate } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TestCaseService } from "app/services/test-case.service";
import { TestCaseStatus } from 'app/enums/test-case-status.enum';
import { MixpanelService } from "app/mixpanel.service";
import { MatDialog } from "@angular/material/dialog";
import { ScreenShortOverlayComponent } from "app/components/webcomponents/screen-short-overlay.component";
import { TestStepService } from "app/services/test-step.service";
import { Socket } from "ngx-socket-io";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationGuard } from "app/shared/guards/authentication.guard";
import { ToastrService } from "ngx-toastr";
import { NotificationsService } from "angular2-notifications";

@Component({
  selector: "app-generatemore-testcase",
  templateUrl: "./generatemore-testcase.component.html",
  styleUrls: ["./generatemore-testcase.component.scss"],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class GeneratemoreTestcaseComponent
  extends BaseComponent
  implements OnInit {
  @Input() isOpen = false;
  @Output() closeEvent = new EventEmitter<void>();
  @Input() testcaseDetails: any;
  @Input() testcasesuggestion: any;
  @Input() version: any;
  @Input() isFromAiChat: boolean = false;
  @Input() errorMessage: any;
  @Input() testcaseId: any;
  @Output() refreshRequested = new EventEmitter<void>();
  loadingSpinner: boolean = false;
  loadingStates: { [key: string]: boolean } = {};
  query: any = '';
  constructor(
    private testCaseService: TestCaseService,
    private mixpanelService: MixpanelService,
    public matModal: MatDialog,
    public teststepService: TestStepService,
    private socket: Socket,
    public translate: TranslateService,
    public authGuard: AuthenticationGuard,
    public toastrService: ToastrService,
    public notificationsService: NotificationsService,








  ) { super(authGuard, notificationsService, translate) }
  ngOnInit(): void {
    console.log(this.testcaseDetails, ">>>>>");
    if (
      !this.testcaseDetails) {
      const queryType = 'session';
      this.refreshData(this.testcaseId, queryType);
    }
  }
  newSteps: string[] = [];
  selectedCaseType: string = 'Positive';
  selectedSteps: any[] = [];
  loading: boolean = false;
  previewTestcaseSuggestion: any;
  groupedSelectedSteps: any = [];
  isEditable: boolean = true;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.testcaseDetails || changes.testcasesuggestion) {
      console.log('Updated testcase details:', this.testcaseDetails, this.testcasesuggestion);
    }
    this.groupSelectedSteps();

  }
  ngOnDestroy() {
    if (this.socket) {
      this.socket.removeAllListeners();
    }
  }
  tooltips = {
    caseTypes: {
      general: 'Basic functionality tests',
      positive: 'Tests with valid inputs',
      negative: 'Tests with invalid inputs',
      edge: 'Tests with boundary conditions'
    }
  };

  addNewStep(testcase: any) {
    console.log(testcase, ">>")
    if (!this.isInNewSteps(testcase)) {
      this.newSteps.push(testcase);
      this.groupSelectedSteps();
    }
  }

  removeStep(index: number) {
    this.newSteps.splice(index, 1);
    this.groupSelectedSteps();
  }

  removeStepByName(stepName: string) {
    const index = this.newSteps.findIndex((step: any) => step.name === stepName);
    if (index > -1) {
      this.removeStep(index);
    }
  }
  removeStepByNamePreview(stepName: string) {
    Object.keys(this.previewTestcaseSuggestion).forEach((sectionKey) => {
      const section = this.previewTestcaseSuggestion[sectionKey];

      const stepIndex = section.steps.findIndex((step: any) => step.name === stepName);

      if (stepIndex !== -1) {
        section.steps.splice(stepIndex, 1);
      }
    });
  }

  groupSelectedSteps() {
    this.groupedSelectedSteps = [];

    this.newSteps.forEach((step) => {
      const sectionKey = Object.keys(this.testcaseDetails).find((key) =>
        this.testcaseDetails[key].steps.includes(step)
      );

      if (sectionKey) {
        // if (!this.groupedSelectedSteps[sectionKey]) {
        //   this.groupedSelectedSteps[sectionKey] = {
        //     title: this.testcaseDetails[sectionKey].title,
        //     steps: [],
        //   };
        // }

        this.groupedSelectedSteps.push(step);
      }
    });
  }

  isInNewSteps(testcase: any): boolean {
    return this.newSteps.some((step: any) => step.name === testcase.name);
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  close(): void {
    this.isOpen = false;
    this.closeEvent.emit();
  }
  expandedIndexes: boolean[] = [];

  toggleExpand(index: number) {
    this.expandedIndexes[index] = !this.expandedIndexes[index];
  }

  hasSelectedSteps(): boolean {
    return Object.keys(this.groupedSelectedSteps).length > 0;
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }


  generateTestCase() {
    const actions = this.getFormattedActions();

    // Prepare your payload
    const payload = {
      query: this.query,
      type: this.selectedCaseType,
      html: this.testcasesuggestion.html,
      url: this.testcasesuggestion.url,
      img: this.testcasesuggestion.img,
      action: actions
    }

    console.log(payload)
    this.loading = true;
    this.testCaseService.suggestTestcaseGenereteAi(payload).subscribe(
      (response: any) => {
        this.loading = false;
        this.previewTestcaseSuggestion = response.data
        console.log('Test case generated successfully', this.previewTestcaseSuggestion);
      },
      error => {
        this.loading = false;
        console.error('Error generating test case', error);
      }
    );
  }
  selectCaseType(caseType: string): void {
    this.selectedCaseType = caseType;
  }
  // Helper method to format the selected steps as actions
  getFormattedActions() {
    const actions = [];
    Object.entries(this.groupedSelectedSteps).forEach(([key, section]: [string, any]) => {
      console.log(this.groupedSelectedSteps, section)
      // if (section.steps && section.steps.length) {
      // section.steps.forEach((step: any) => {
      actions.push({ action: section.action });
      // });
      // }
    });
    return actions;
  }
  updateStepName(step: any): void {
    console.log(step, "?????")
    const updatedValue = step.action?.trim();
    console.log(updatedValue)
  }
  onInputChange(event: any, step: any): void {
    const updatedValue = event.target.value;
    const extractedAction = updatedValue.split(' as value ')[0];
    step.action = extractedAction;
  }


  addAllSteps(section: string): void {
    const steps = this.testcaseDetails[section]?.steps;
    if (steps) {
      steps.forEach((testCase) => {
        console.log(testCase, "testCasetestCase")
        this.addNewStep(testCase);

      });
    }
  }

  addBlankStep(sectionKey: string): void {
    const section = this.previewTestcaseSuggestion[sectionKey];
    if (section) {
      section.steps.push({ action: 'Enter task' });
    }
  }

  async createTestcase(data) {
    const sectionKey = data.key;
    this.loadingStates[sectionKey] = true;
    let testCase = await this.createTestCaseCreateData(data);
    this.testCaseService.autocreate(testCase).subscribe(
      (testcase) => {
        this.mixpanelService.track('Test Case Created', {
          "id": testcase.id,
          "title": testCase.name,
          "status": testCase.status,
          "method": "AI-chat"
        });
        this.loadingStates[sectionKey] = false;

        if (testcase.id) {
          window.open(`${origin}/td/cases/${testcase.id}/steps`, '_blank');
        }
      },
      error => {
        this.loadingStates[sectionKey] = false;
      }
    );
  }
  async createTestCaseCreateData(data) {
    console.log(data);
    let testCaseSteps = [];

    const staticNavigateStep = {
      action: `Navigate to <span data-key="test-data" data-event-key="href" class="test_data action-flex-auto" data-test-data-type="undefined">${this.testcasesuggestion.url}</span>`,
      name: `Navigate to ${this.testcasesuggestion.url}`,
      href: this.testcasesuggestion.url,
      testData: this.testcasesuggestion.url,
      actionName: "navigateToUrl", // Static actionName
      type: "ACTION_TEXT",
      naturalTextActionId: 425,
      disabled: false,
      ignoreStepResult: false,
      visualEnabled: false,
      position: 0,
      waitTime: 30,
      priority: "MAJOR",
      isRunTime: false,
      index: 0,
      metadata: data.description || "",
      event: {
        customEvent: "navigateToUrl",
        href: this.testcasesuggestion.url,
        pwLocator: [null],
        selector: null
      }
    };
    testCaseSteps.push(staticNavigateStep);
    if (Array.isArray(data.value.steps)) {
      data.value.steps.forEach((step, index) => {
        console.log(step)
        let Ai_agent_template = {
          action: `AI Agent <span data-key="element" data-event-key="ai_task" class="element action-flex-auto">${step.action} ${step.value && step.value.length > 0 ? 'as value  ' + step.value.join(', ') : ''}</span>`,
          actionName: "ai_text_actions",
          event: {
            customEvent: "ai_text_actions",
            ai_task: step.action,
            description: step.action,
            type: "create_step"
          },
          naturalTextActionId: 568,
          type: "ACTION_TEXT",
          disabled: false,
          ignoreStepResult: false,
          visualEnabled: false,
          position: index + 1
        };
        testCaseSteps.push(Ai_agent_template);
      });
    } else {
      console.error("Invalid data.steps: It should be an array.");
    }

    return {
      "isExtensionUsed": false,
      "name": data.value.title || 'Default Test Case Name',
      "description": null,
      "status": "READY",
      "sendMailNotification": false,
      "isStepGroup": false,
      "priorityId": 1,
      "type": 1,
      "preRequisite": null,
      "isDataDriven": false,
      "workspaceVersionId": 1,
      "deleted": false,
      "testDataStartIndex": 0,
      "tags": [],
      "steps": testCaseSteps
    };
  }
  createTestCaseall() {
    if (this.previewTestcaseSuggestion) {
      Object.keys(this.previewTestcaseSuggestion).forEach((key) => {
        const section = this.previewTestcaseSuggestion[key];
        const payload = {
          key: key,
          value: section
        };
        console.log(payload);
        this.createTestcase(payload);
      });
    }
  }
  openScreenShort(goldenScreenshot) {
    this.matModal.open(ScreenShortOverlayComponent, {
      data: { screenShortUrl: goldenScreenshot },
    });
  }
  refreshData(testcaseId: string, queryType?: string) {
    this.testcaseDetails = '';
    this.errorMessage = '';
    this.loadingSpinner = true;
    if (!queryType) {
      this.testcasesuggestion = '';

    }
    const apiUrl = queryType ? `${this.testcaseId}?type=${queryType}` : this.testcaseId;
    this.teststepService.suggestionTestCaseGenerate(apiUrl).subscribe(
      (res: any) => {
        if (res?.topic) {
          console.log(res)
          this.socket.on(res.topic, (data: any) => {
            console.log(data)
            if (data?.data?.result?.status == "FAILURE") {
              this.errorMessage = data?.data?.result.message
              this.loadingSpinner = false;

            }
            if (data?.data?.result?.status == "SUCCESS") {
              this.testcaseDetails = data.data.result.currentInfo;
              this.testcasesuggestion = data.data.result.previousInfo;
              this.loadingSpinner = false;

            }
          });
        } else {
          this.loadingSpinner = false;
        }
      },
      (error: any) => {
        console.error("Error fetching test case suggestions:", error);
        this.loadingSpinner = false;
      },
      () => {

        console.log("Suggestion test case observable completed.");
      }
    );
  }
  //  this.refreshRequested.emit(session); // Notify parent
}


