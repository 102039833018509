<div class="stesp-variables">
    <div class="header">
        <span>
            Steps Variables
        </span>
        <button [matTooltip]="'hint.message.common.close' | translate" class="close" mat-dialog-close>
        </button>
    </div>
    <div class="body">
        <!-- <div class="form-group pb-10" *ngIf="parametersInvalid()">
            <div class="error noarrow top-0 position-relative ml-14" [translate]="'environments.form.message.no_parameters'"></div>
        </div> -->
        <form class="ts-form">
            <div class="ml-18 mb-10 pointer" *ngIf="selectedList.length && edit">
                <i class="fa-trash-thin" [matTooltip]="'hint.message.common.remove_selected_items'|translate"
                    (click)="removeMultipleParameters()">
                </i>
            </div>
            <div class="environments-table">
                <div class="environments-table-header">
                    <span>
                        <mat-checkbox [(ngModel)]="selectAll" [ngModelOptions]="{standalone: true}"
                            (change)="selectAllToggle(selectAll)" class="mat-checkbox">
                        </mat-checkbox>
                    </span>
                    <span [translate]="'environments.form.table_header.parameter_name'"></span>
                    <span class="pl-16" [translate]="'environments.form.table_header.parameter_value'"></span>
                    <span></span>
                </div>
                <div class="environments-table-body">
                    <div class="environments-table-rows" [formGroup]="parametersControl"
                        *ngFor="let parametersControl of rowControls().controls; let index = index; let isLast=last">
                        <span class="my-auto">
                            <mat-checkbox [class.d-none]="!edit" formControlName="selected"
                                (change)="addOrRemoveFromSelectList()" [checked]="parametersControl.value.selected"
                                class="mat-checkbox">
                            </mat-checkbox>
                        </span>
                        <span class="position-relative" [matTooltip]="!edit ? parametersControl.value.key:''"
                            [matTooltipPosition]="'right'">
                            <input type="textbox" formControlName="key" [readOnly]="!edit"
                                [class.border-fail-1]="isDuplicateParameters(parametersControl.value.key)"
                                [class.result-1]="isDuplicateParameters(parametersControl.value.key)"
                                [placeholder]="'common.key' | translate" (blur)="andEmptyRowIfMissing()" />
                        </span>
                        <span [matTooltip]="!edit ? parametersControl.value.value:''" [matTooltipPosition]="'right'">
                            <span [class.view-input]="!edit" class="input-encrypt">
                                <input [readOnly]="!edit" class="ts-col-100-25 input-encrypt-field" [type]="'textbox'"
                                    formControlName="value" [class.border-fail-1]="isEmptyValue(parametersControl)"
                                    [class.result-1]="isEmptyValue(parametersControl)"
                                    [placeholder]="'common.value' | translate" (blur)="andEmptyRowIfMissing()" />
                            </span>
                        </span>
                        <span class="text-center my-auto">
                            <i class="fa-trash-thin" [class.d-none]="isLast && edit"
                                [matTooltip]="'hint.message.common.remove_row'| translate"
                                (click)="removeRowControl(index)">
                            </i>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="footer">
        <button class="theme-btn-clear-default" [translate]="'btn.common.cancel'" mat-dialog-close>
        </button>
        <button 
            [disabled]="!hasValidParameters()" 
            (click)="createAndUpdateVariables()"
            class="btn ml-14 theme-btn-primary"
            [textContent]="'btn.common.create' | translate"></button>
    </div>
</div>