import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {collapse} from "../../../shared/animations/animations";
import {CdkConnectedOverlay} from "@angular/cdk/overlay";

@Component({
  selector: 'app-test-data-filter',
  templateUrl: './test-data-filter.component.html',
  animations: [collapse]
})
export class TestDataFilterComponent {
  @Input() filterByEnumList: string[];
  @Input() filterByValue: string;
  @Input() translatePrefix: string;
  @Output() filterAction = new EventEmitter<{filterBy: string,filterByStr:string}>();

  openFilter: boolean = false;
  @ViewChild('sortByOptions') overlayDir: CdkConnectedOverlay;
  private clickedOnFilterToClose: boolean = false;

  openFilterByOptions(event:Event) {
    event.preventDefault();
    event.stopPropagation();
    
    if (this.clickedOnFilterToClose) {
      this.clickedOnFilterToClose = false;
      return;
    }
    
    if (this.openFilter) {
      this.openFilter = false;
      return;
    }
    
    this.openFilter = true;
    setTimeout(() => {
      if (this.overlayDir && this.overlayDir.overlayRef) {
        this.overlayDir.overlayRef._outsidePointerEvents.subscribe(res => {
          this.overlayDir.overlayRef.detach();
          this.clickedOnFilterToClose = this.eventFromFilterButton(res);
          this.openFilter = false;
        });
      }
    }, 200);
  }

  filter(filterByValue: string) {
    let filtersMap = new Map<string, string>([['all',undefined],['used','true'],['unused','false']]);
    this.filterByValue = filterByValue;
    this.filterAction.emit({
      filterBy: filterByValue,
      filterByStr: filtersMap.get(filterByValue)
    });
    
    if (this.overlayDir && this.overlayDir.overlayRef) {
      this.overlayDir.overlayRef.detach();
    }
    this.openFilter = false;
    this.clickedOnFilterToClose = false;
  }

  private eventFromFilterButton(res) {
    return res.path.find(path => path.nodeName == "TEST-DATA-FILTER")
  }
}

