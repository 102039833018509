<div class="h-100" [class.ts-col-100]="!(showRunDetails || showFilter)"
     [class.ts-col-65]="showRunDetails || showFilter">
  <div
    class="theme-section-header align-items-center"
    [style.padding]="isExecutionRunning ? '0px 24px 0px 20px' : testPlanResult.canReRun ? '0px 24px 0px 20px': '0px 24px 0px 20px'">
    <div class="dropdown section-title mouse-over hover-dropdown">
      <div class="btn-group  text-nowrap">
        <a class="rb-medium" target="_blank">
          <span [translate]="'runs.details.test_case_result'"></span>
          <i class="fa-caret-down pl-6 fz-10" aria-hidden="true"></i>
        </a>
      </div>
      <div
        class="dropdown-menu drop-down-transparent min-w">
        <ul
          class="bg-white border-rds-4 ng-scope p-15 shadow-all2-b4">
          <li
            class="border-rds-10 btn rb-medium grey-on-hover text-dark"
            (click)="toggleView('TCR')">
            <span [translate]="'runs.details.test_case_result'"></span>
            <i class="fa-tick pl-5"></i>
          </li>
          <li
            (click)="toggleView('TSR')"
            class="border-rds-10 btn rb-medium grey-on-hover text-dark ml-0">
            <span [translate]="'runs.details.test_suite_result'"></span>
          </li>
          <li
            (click)="toggleView('TMR')"
            class="border-rds-10 btn rb-medium grey-on-hover text-dark ml-0">
            <span [translate]="'runs.details.test_machine_result'"></span>
          </li>
        </ul>
      </div>
    </div>
    <div class="ml-auto d-flex align-items-center">
      <app-auto-refresh
        class="d-inline-block"
        *ngIf="isExecutionRunning"
        [autoRefreshIntervalTime]="autoRefreshInterval"
        (autoRefreshIntervalTime)="changeAutoRefreshTime($event)"
        (autoRefreshToggle)="toggleAutoRefresh($event)"></app-auto-refresh>
      <div class="d-flex">
        <i
          [class.fa-search]="!isSearchEnable"
          [class.fa-search-right.pr-4]="isSearchEnable"
          (click)="toggleSearch()"
          class="section-action-icons pointer"
          [matTooltip]="'hint.message.common.search' | translate"></i>

        <div
          *ngIf="isSearchEnable"
          class="search-form-group ts-form text-right p-0"
          aria-hidden="false">
          <input
            #searchMachineInput [(ngModel)]="inputValue"
            type="text"
            class="form-control d-inline-block w-85 p-0"
            [placeholder]="'agents.mobile.inspection.search_element' | translate" aria-invalid="false">
          <i [ngClass]= "{'visibility-visible' : inputValue}"  [matTooltip]="'hint.message.common.clear' | translate"
             class="fa-close-alt text-t-secondary fz-12 pt-2 mr-5 pointer visibility-hidden" (click)="clearSearch()"></i>
        </div>
      </div>
      <app-re-run-button
        *ngIf="testPlanResult.canReRun && testPlanResult?.testPlan.id != '1'"
        [testPlanResult]="testPlanResult"></app-re-run-button>
       <div class="dropdown section-title mouse-over d-inline-block">
        <div class="btn-group">
          <mat-icon class="section-action-icons download-icon pointer" style="font-size: 21px !important;">download</mat-icon>
          <!-- <i class="fa-download section-action-icons pointer"></i> -->
        </div>
        <div
          class="dropdown-menu drop-down-transparent min-w left-md">
          <ul
            class="bg-white border-rds-4 ng-scope p-15 shadow-all2-b4">
            <li
              class="border-rds-10 btn rb-medium grey-on-hover text-dark">
              <a
                (click)="downloadExcelFile()"
                class="text-dark text-decoration-none"
                [translate]="'runs.details.export_as_xls'"></a>
            </li>
            <li
              class="border-rds-10 btn rb-medium grey-on-hover text-dark  ml-0">
              <a
                (click)="downloadPDFFile()"
                class="text-dark text-decoration-none"
                [translate]="'runs.details.export_as_pdf'"></a>
            </li>
<!--            <li-->
<!--              class="border-rds-10 btn rb-medium grey-on-hover text-dark ml-0">-->
<!--              <a-->
<!--                [href]="'/reports/junit/'+testPlanResult?.id"-->
<!--                class="text-dark text-decoration-none"-->
<!--                target="_blank" [translate]="'runs.details.export_as_junit'"></a>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
      <div class="position-relative">
        <span
          [class.active]="showFilter"
          [class.reset]="isFilterApplied"
          class="filter-icon-with-reset">
          <i
            [class.filtered]="isFilterApplied"
            [class.mr-n5]="isFilterApplied"
            class="filter-icon"
            (click)="toggleFilter()"
            [matTooltip]="'hint.message.common.filter' | translate">
          </i>
          <i
            *ngIf="isFilterApplied"
            [matTooltip]="'btn.common.reset' | translate"
            (click)="resetFilter()"
            class="reset"></i>
        </span>
      </div>
      <div>
        <i
          class="fa-info section-action-icons pointer"
          [class.active]="showRunDetails"
          (click)="toggleDetails()"
          [matTooltip]="'hint.message.common.details' | translate"></i>
      </div>
    </div>
  </div>
  <cdk-virtual-scroll-viewport itemSize="59" class="bg-default theme-section-container card-container virtual-scroll-viewport theme-w-o-h-scroll x-lg-h">
    <div *cdkVirtualFor='let testCaseResult of testCaseResultsDataSource'>
      <div  *ngIf="testCaseResult?.testCase?.name" class="list-card test-case-results bordered sm-pm" style="padding: 0px;">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <div class="d-flex fz-12 align-items-center" [class.ts-col-20]="showFilter || showRunDetails" [class.ts-col-13]="!(showFilter || showRunDetails)">
            <picture>
              <img src="/assets/images/new-tab.svg" alt="new-tab" width="20px" (click)="openInNewTab($event,testCaseResult.id)">
            </picture>
            <app-re-run-icon [resultEntity]="testCaseResult?.lastRun"></app-re-run-icon>
            <app-result-pie-chart-column [width]="24" [height]="24" [resultEntity]="testCaseResult.lastRun || testCaseResult"></app-result-pie-chart-column>
          </div>
          <div class="d-flex flex-wrap" [class.ts-col-80]="showFilter || showRunDetails" [class.ts-col-87]="!(showFilter || showRunDetails)">
            <div class="ts-col-100">
              <div *ngIf="panelOpenState == false" class="theme-breadcrumb list-title"   [class.ts-col-85]="showFilter || showRunDetails">
                <div [routerLink]="['/td', 'cases',  testCaseResult.testCase.id]" target="_blank" style="font-size: 13px !important;color: blue !important;">C - {{testCaseResult?.testCase?.id}}</div>
                <div class="theme-breadcrumb-item mw-50 ml-3" [routerLink]="['/td/test_case_results', testCaseResult.id]" style="font-size: 13px !important;color: black !important;" [matTooltip]="testCaseResult?.testCase?.name" [textContent]="testCaseResult?.testCase?.name"></div>
              </div>
              <div *ngIf="panelOpenState == true" [routerLink]="['/td/test_case_results', testCaseResult.id]" class="list-title text-break mr-10" [textContent]="testCaseResult?.testCase?.name"> </div>
            </div>
            <div class="ts-col-100 pt-4 text-t-secondary fz-12 d-flex align-items-center">
              <div
                class="theme-breadcrumb justify-content-start"
                [class.ts-col-25]="showFilter || showRunDetails"
                [class.ts-col-30]="!(showFilter || showRunDetails)">
                <a
                  *ngIf="testCaseResult?.testDeviceResult?.testDeviceSettings?.title"
                  class="theme-breadcrumb-item mw-50"
                  [routerLink]="['/td/machine_results', testCaseResult?.environmentResultId]"
                  [matTooltip]="testCaseResult?.testDeviceResult?.testDeviceSettings?.title"
                  [textContent]="testCaseResult?.testDeviceResult?.testDeviceSettings?.title"></a>
                <a
                  class="theme-breadcrumb-item mw-50"
                  [routerLink]="['/td/suite_results', testCaseResult?.suiteResultId]"
                  [matTooltip]="testCaseResult?.testSuite?.name"
                  [textContent]="testCaseResult?.testSuite?.name"></a>
              </div>
              <div
                class="px-10 text-center ts-col-45">
                <app-test-machine-info-column
                  [environmentResult]="testCaseResult?.testDeviceResult"
                  [testPlanResult]="testCaseResult?.testDeviceResult?.testPlanResult"
                  [testDevice]="testCaseResult?.testDeviceResult?.testDevice"></app-test-machine-info-column>
              </div>
              <div
                class="d-flex position-absolute right-0" style="margin-top: -12px;">
                <div
                  class="text-nowrap"
                  [textContent]="testCaseResult?.startTime | date:'MMM d, h:mm:ss'"></div>
                <div class="pl-8 mr-15">
                  <app-duration-format [duration]="testCaseResult?.duration"></app-duration-format>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel-header>
        <!-- Collaps -->
        <div [translate]="'page_title.run_details'">
          <div [translate]="'step_result.recording'" *ngIf="!!testCaseResult?.screenRecordingUrl">
            <video width="100%" height="100%" src="{{testCaseResult?.screenRecordingUrl}}" type="video/webm" autoplay controls>
              <!-- <source src="{{testCaseResult?.screenRecordingUrl}}" type="video/webm"> -->
            </video>
          </div>
          <div class="rb-medium ts-col-100 mt-10" *ngIf="!!testCaseResult?.traceViewerUrl">
            <a href="https://trace.playwright.dev/?trace={{testCaseResult?.traceViewerUrl}}" target="_blank" style="color: blue;">
              <label class="rb-medium" [translate]="'message.common.trace_viewer_url'"></label>
            </a>
          </div>
          <div class="rb-medium ts-col-100 mt-10" *ngIf="!!testCaseResult?.apiTraceViewerUrl">
            <a href="https://trace.playwright.dev/?trace={{testCaseResult?.apiTraceViewerUrl}}" target="_blank" style="color: blue;">
              <label class="rb-medium" [translate]="'message.common.api_trace_viewer_url'"></label>
            </a>
          </div>
          <div *ngIf="testCaseResult?.gifUrl" class="rb-medium ts-col-100 mt-10">
            <a href="{{testCaseResult?.gifUrl}}" target="_blank" style="color: blue;">
              <label class="rb-medium " [translate]="'message.common.gif_url'"> </label>
            </a>
            <!-- <div class="details-info text-wrap"> </div> -->
          </div>
          <div *ngIf="!testCaseResult?.executedResult">
            <div class="details-items ts-col-100 mt-10">
              <!-- Show following attributes if test case is failed-->
              <label  class="details-title" [translate]="'message.common.actual_result'" ></label>
              <div class="details-info text-wrap" [textContent]="testCaseResult?.executedResult?.failed_test_steps[0].actual_result"></div>
            </div>
            <div class="details-items ts-col-100 mt-10">
              <label class="details-title" [translate]="'message.common.expected_result'" ></label>
              <div class="details-info text-wrap" [textContent]="testCaseResult?.executedResult?.failed_test_steps[0].expected_result"></div>
            </div>
            <div class="details-items ts-col-100 mt-10">
              <label class="details-title" [translate]="'message.common.failed_step_index'" ></label>
              <div class="details-info text-wrap" [textContent]="testCaseResult?.executedResult?.failed_test_steps[0].failed_step_index"></div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    </div>
    <div *ngIf="testCaseResultsDataSource.isEmpty" class="empty-full-container-transparent-bg">
      <div class="empty-full-content">
        <div class="empty-run-xs"></div>
        <div
          class="text-t-secondary pt-30 pb-18"
          [translate]="'message.common.search.not_found'"></div>
      </div>
    </div>
    <app-placeholder-loader *ngIf="testCaseResultsDataSource?.isFetching"></app-placeholder-loader>
  </cdk-virtual-scroll-viewport>
</div>
<div *ngIf="showRunDetails" class="ts-col-35">
  <app-run-details-quick-info
    [showList]="'TCR'"
    [testPlanResult]="testPlanResult"
    (filterAction)="filter($event)"
    (toggleDetailsAction)="toggleDetails()"></app-run-details-quick-info>
</div>

<div *ngIf="showFilter" class="ts-col-35">
  <app-test-case-result-filter
    [filterResult]="filterResult"
    [testPlan]="testPlanResult?.testPlan"
    (filterAction)="filter($event)"
    (toggleFilterAction)="toggleFilter()"></app-test-case-result-filter>
</div>
