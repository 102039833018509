<div class="workflow-container">
    <header class="workflow-header">
        <h1 class="workflow-title">AI Data Analyst</h1>
    </header>

    <div class="login-section">
        <button (click)="redirectToLogin()" class="login-button">
            <span>Login to Camel AI</span>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 18L15 12L9 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
    </div>

    <div class="iframe-container" [class.fullscreen]="isFullscreen">
        <div class="loading-overlay" *ngIf="isLoading">
            <div class="spinner"></div>
            <p>Loading workflow...</p>
        </div>
        <iframe
            #workflowFrame
            [src]="iframeUrl"
            [ngStyle]="{
                'height': isFullscreen ? '100vh' : '700px'
            }"
            width="100%"
            (load)="onIframeLoad()"
            title="Workflow Editor"
        >
        </iframe>
    </div>
</div>