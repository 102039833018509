<div class="w-100 h-800">
  <div class="theme-overlay-container">
    <div *ngIf="!plug">Loading...</div>
    <div *ngIf="plug">
      <div class="theme-overlay-header">
        <span class="theme-overlay-title" [translate]="'settings.plugins.contextqa_integration'" ></span>
        <button class="theme-overlay-close" type="button"
          [matTooltip]="'hint.message.common.close' | translate"
          mat-dialog-close>
        </button>
      </div>

      <div class="theme-overlay-content">
        <form class="ts-form pt-30">
          <div class="form-group ts-form">
            <label class="ts-col-80 text-t-secondary mb-2" [translate]="'settings.plugins.useremail'"></label>
            <div class="text-truncate rb-medium" [textContent]="plug.metaData.user_name"></div>
          </div>
          <div *ngIf="plug?.metaData?.contextqa_installed === true || VerifyResponse?.install === true" class="success-box">
            <mat-icon class="success-icon">check_circle</mat-icon>
            <p> ContextQa Plugins have been successfully installed in your application.</p>
          </div>
          <div *ngIf="!plug?.metaData?.contextqa_installed || plug?.metaData?.contextqa_installed === false || VerifyResponse?.install === false" class="alert-box">
            <mat-icon class="alert-icon">warning</mat-icon>
            <p>ContextQa Plugins are not installed in your application. Click the 'Verify' button to check.</p>
          </div>
          <div class="code-snippet-container border rounded p-4">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h4 class="m-0">Add this code to your website</h4>
              <button class="theme-btn-secondary" (click)="copyCode()" mat-button>
                <mat-icon class="mr-2">content_copy</mat-icon>
              </button>
            </div>
            <pre class="code-block bg-light p-3 rounded"><code [innerText]="getScriptContent()"></code></pre>
          </div>
        </form>
      </div>

      <div class="theme-overlay-footer" style="margin-top: 60px;">
          <button type="button" mat-dialog-close class="theme-btn-clear-default" 
            [translate]="'btn.common.cancel'">
          </button>
          <button type="submit" *ngIf="!plug?.metaData?.contextqa_installed" 
            [disabled]="saving" 
            class="theme-btn-primary" 
            (click)="Verify()">
            <span>Verify</span>
          </button>
          <button type="submit" 
            [disabled]="saving" 
            class="btn btn-delete" (click)="delete()">
            <span class="fa-trash-thin mr-5"></span>
            <span [translate]="'btn.common.delete'"></span>
          </button>
      </div>
    </div>
  </div>
</div>
