import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TestCase } from "app/models/test-case.model";
import { TestStepService } from "app/services/test-step.service";
import { Socket } from "ngx-socket-io";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { TestStep } from "../../../../models/test-step.model";
import { LicenceSevices } from "app/shared/services/license.service";
import { ChatModelComponent } from "../chat-model/chat-model.component";
import { TranslateService } from "@ngx-translate/core";

interface ChatMessage {
  text: string;
  addedBy: "USER" | "SYSTEM";
  stepStatus?: string;
  stepId?: string;
  isFailedStep?: boolean;
  newLocator?: string;
  newMetadata?: string;
}

interface StepLog {
  text: string;
  complete: boolean;
  id: string;
}

@Component({
  selector: "app-execution-model",
  templateUrl: "./execution-model.component.html",
  styleUrls: ["./execution-model.component.scss"],
})
export class ExecutionModelComponent implements OnInit, DoCheck {
  @ViewChild("logContainer") private logContainer: ElementRef;
  @ViewChild("chatContainer") private chatContainer: ElementRef;

  public Prop: any;
  public loader: any;
  public TestCase: any;
  public TestSteps: any[];
  public TestStep: TestStep[];
  public ExecutedStepsData: TestCase[];
  public time: any;
  public failSteps: any;
  public testRunId: any;
  public successSteps: any;
  public newMetadata: any;
  public stepsData: any = [];
  public chatLogs: any[] = [];
  public stopExecution: any;
  public activeLog: string = "console";
  stepGroupSteps: any = [];
  private lastLogLength: number = 0;
  public Conversation: any;
  public message: any;
  public faildStepsDetail: any;
  private extensionId = "pgadpooodaipbhbchnojijmlkhpamadh";

  private timerSubscription: Subscription | null = null;
  private inputEnabled = new BehaviorSubject<boolean>(false);
  public inputEnabled$ = this.inputEnabled.asObservable();

  public remainingTime$ = new BehaviorSubject<number>(0);
  private timerStarted: boolean = false;
  public locatorNotDetect: boolean = false;
  testCaseExecutionLog: any;
  public origin: string = "";

  public readonly CIRCLE_CIRCUMFERENCE = 2 * Math.PI * 52; // 52 is the radius of our circle

  // Add this property to track loading state
  public isStoppingExecution: boolean = false;

  public displayedLogs: { [stepId: string]: StepLog[] } = {};

  private chatDialogRef: MatDialogRef<ChatModelComponent> | null = null;


  constructor(
    private dialogRef: MatDialogRef<ExecutionModelComponent>,
    private testStepService: TestStepService,
    public router: Router,
    private socket: Socket,
    private cdr: ChangeDetectorRef,
    public LicenceSevices: LicenceSevices,
    private dialog: MatDialog,
    public translate: TranslateService,
  ) { }


  ngOnInit(): void {
    this.FetchLicence();
    this.origin = window.location.hostname.split(".")[0];
  }

  ngDoCheck() {
    if (
      this.Prop &&
      this.Prop.log &&
      this.Prop.log.length > this.lastLogLength
    ) {
      const newLogs = this.Prop.log.slice(this.lastLogLength);
      newLogs.forEach((log) => {
        this.addNewLog(log, this.Prop.id);
        this.addChatLog(log, this.Prop);
      });
      this.lastLogLength = this.Prop.log.length;

      this.TestSteps?.forEach(step => {
        if (step.executionData?.stepStatus === 'CONVERSATION') {
          if (!this.chatDialogRef) {
            this.openLinkedTestPlansDialog(step);
          } else {
            this.updateDialogData(step);
          }
        }
      });
    }
    if (
      this.Prop &&
      this.Prop.stepStatus === "LOCATOR_NOT_DETECT" &&
      !this.timerStarted
    ) {
      this.startTimer();
      this.timerStarted = true;
    }
    if (
      this.Prop &&
      this.Conversation &&
      this.Conversation.stepStatus === "TIMEOUT" &&
      !this.timerStarted
    ) {
      this.startTimer();
      this.timerStarted = true;
    }

    if (this.Prop && this.Prop.status === 'COMPLETED' && this.chatDialogRef) {
      this.chatDialogRef.close();
      this.chatDialogRef = null;
    }
  }
  FetchLicence() {
    this.LicenceSevices.getAll().subscribe((data: any) => {
      this.testCaseExecutionLog = data.featureSupport.testCaseExecutionLog;

    })
  }
  addChatLog(log: string, Prop: any) {
    const validSteps = ['WAITING_FOR_STOP', 'TIMEOUT', 'WAITING_STATUS', 'AI_AGENT', 'AI_ASK', 'DEBUGGE_WITH_AI'];
    if (validSteps.includes(Prop.stepFrom)) {
      const cleanedLog = log.replace(/^Step: \d+ : /, '');
      const chatMessage: ChatMessage = {
        text: cleanedLog,
        addedBy: 'SYSTEM',
        stepStatus: Prop.stepStatus,
        stepId: Prop.id,
        isFailedStep: Prop.stepStatus === 'CONVERSATION' && Prop.stepFrom === 'DEBUGGE_WITH_AI' ? true : false
      };
      if (Prop.stepStatus === 'CONVERSATION' && Prop.stepFrom === 'DEBUGGE_WITH_AI') {
        this.newMetadata = '';
      }
      this.chatLogs.push(chatMessage);
      this.cdr.detectChanges();
      this.scrollChatToBottom();
    }
  }

  private addNewLog(log: string, stepId: string): void {
    if (!this.displayedLogs[stepId]) {
      this.displayedLogs[stepId] = [];
    }

    this.displayedLogs[stepId].push({
      text: "",
      complete: false,
      id: stepId
    });
    this.simulateTyping(
      log,
      this.displayedLogs[stepId].length - 1,
      stepId
    );
  }

  redirectToCE() {
    if (this.Conversation.stepStatus === "LOCATOR_NOT_DETECT") {
      this.testStepService.show(this.Conversation.id).subscribe((steps) => {
        this.faildStepsDetail = steps;
        if (this.faildStepsDetail) {
          if (chrome && chrome.runtime) {
            let data = {
              type: "test_case",
              // id: testcase.id,
              // result: testcase,
              action: "openSidePanelFromPortal1",
              origin: window.location.hostname.split(".")[0],
              // origin: "dtest01",
              stepNumber: 2, //Add position - 1
              faildStepsDetail: this.faildStepsDetail,
              jwt: localStorage.getItem("_t"),
              userEmail: localStorage.getItem("useremail"),
            };
            chrome.runtime.sendMessage(
              this.extensionId,
              { message: "openSidePanelFromPortal1", data: data },
              (data: any) => { }
            );
            // this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            const url =
              this.faildStepsDetail.event.targetPageUrl ||
              this.faildStepsDetail.event.href;
            window.open(url, "_blank");
          } else {
            // this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            const url =
              this.faildStepsDetail.event.targetPageUrl ||
              this.faildStepsDetail.event.href;
            window.open(url, "_blank");
          }
        }
      });
    }
  }
  private simulateTyping(log: string, index: number, stepId: string): void {
    let charIndex = 0;
    const interval = setInterval(() => {
      if (charIndex < log.length) {
        this.displayedLogs[stepId][index].text += log[charIndex];
        charIndex++;
        this.cdr.detectChanges();
        this.scrollToBottom();
      } else {
        clearInterval(interval);
        this.displayedLogs[stepId][index].complete = true;
        this.cdr.detectChanges();
      }
    }, 50);
  }

  private scrollToBottom(): void {
    if (!this.logContainer?.nativeElement) {
      console.warn("logContainer is not initialized.");
      return;
    }

    try {
      this.logContainer.nativeElement.scrollTop =
        this.logContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error("Error while scrolling to bottom:", err);
    }
  }

  private scrollChatToBottom(): void {
    try {
      if (!this.chatContainer?.nativeElement) return;
      this.chatContainer.nativeElement.scrollTop =
        this.chatContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  /** Re Run */
  reRun(value?: boolean): void {
    this.dialogRef.close({ data: "you confirmed" });
    this.testStepService.onRerun.emit();
  }

  /** Close Dialog */
  closeDialog() {
    this.dialogRef.close({ data: "you confirmed" });
  }

  /** Get Step Data */
  expandedIndex: number | null = null;
  getStepDetailData(data: any, index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = null;
    } else {
      this.expandedIndex = index;
    }
    if (data.stepGroupId) {
      let query = "testCaseId:" + data.stepGroupId;
      this.testStepService.findAll(query).subscribe((res) => {
        this.stepGroupSteps = res;
      });
    }
  }

  /** Re direct to test run  */
  redirectToRunDetail() {
    const url = this.router
      .createUrlTree(["/td/runs/", this.testRunId])
      .toString();
    window.open(url, "_blank");
  }

  sentevent() {
    if (
      this.Conversation &&
      this.Conversation.stepStatus == "LOCATOR_NOT_DETECT"
    ) {
      this.chatLogs.push({ text: "Locator updated", addedBy: "USER" });
      this.socket.emit("internal", {
        message: "Locator updated",
        topic: this.Conversation?.replyBack_id,
      });
    } else {
      this.chatLogs.push({ text: this.message, addedBy: "USER" });
      this.socket.emit("internal", {
        message: this.message,
        topic: this.Conversation?.replyBack_id,
      });
    }
    this.scrollChatToBottom();
    this.Conversation = null;
    this.message = "";
    this.timerStarted = false;
  }

  private startTimer() {
    this.stopTimer(); // Ensure any existing timer is stopped
    this.inputEnabled.next(true);
    this.locatorNotDetect = true;

    const duration = 2 * 60; // 2 minutes in seconds
    const TimeoutDuration = 1 * 50;
    if (this.Conversation.stepStatus === "TIMEOUT") {
      this.remainingTime$.next(TimeoutDuration);
    } else {
      this.remainingTime$.next(duration);
    }

    this.timerSubscription = timer(0, 1000)
      .pipe(takeWhile(() => this.remainingTime$.value > 0))
      .subscribe(() => {
        const currentTime = this.remainingTime$.value;
        if (currentTime > 0) {
          this.remainingTime$.next(currentTime - 1);
        }
        if (currentTime === 1) {
          this.inputEnabled.next(false);
          this.stopTimer();
          if (this.Conversation.stepStatus === "TIMEOUT") {
            this.Conversation = null;
          }
          this.timerStarted = false;
          this.locatorNotDetect = false;
        }
      });
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  public formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  public getDashOffset(remainingTime: number): number {
    const progress = 1 - remainingTime / (2 * 60); // 2 minutes total
    return this.CIRCLE_CIRCUMFERENCE * progress;
  }

  stopExecutionevent() {
    if (!this.stopExecution?.id) {
      return;
    }

    try {
      this.isStoppingExecution = true;
      this.socket.emit("internal", {
        message: "stop_generation",
        topic: this.stopExecution.id,
      });

      setTimeout(() => {
        this.Conversation = null;
        this.message = "";
        this.timerStarted = false;
        this.isStoppingExecution = false;
      }, 1000);
    } catch (error) {
      this.isStoppingExecution = false;
    }
  }

  private updateDialogData(steps: any) {
    if (this.chatDialogRef) {
      const newData = {
        Prop: this.Prop,
        Conversation: {
          replyBack_id: this.Conversation?.replyBack_id || null,
          stepStatus: steps.executionData?.stepStatus || null
        },
        chatLogs: this.chatLogs || [],
        stepData: steps
      };

      this.chatDialogRef.componentInstance.updateData(newData);
    }
  }

  openLinkedTestPlansDialog(steps: any) {
    if (!this.chatDialogRef) {
      this.chatDialogRef = this.dialog.open(ChatModelComponent, {
        width: '800px',
        height: '600px',
        data: {
          Prop: this.Prop,
          Conversation: {
            replyBack_id: this.Conversation?.replyBack_id || null,
            stepStatus: steps.executionData?.stepStatus || null
          },
          chatLogs: this.chatLogs || [],
          stepData: steps
        },
        panelClass: ['mat-dialog', 'rds-none', 'chat-model-dialog'],
        disableClose: false,
      });

      this.chatDialogRef.afterClosed().subscribe(() => {
        this.chatDialogRef = null;
      });
    }
  }
}
