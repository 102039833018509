<div class="workflow-container">
    <header class="workflow-header">
        <h1 class="workflow-title">Workflows</h1>
    </header>

    <div class="iframe-container" [class.fullscreen]="isFullscreen">
        <div class="loading-overlay" *ngIf="isLoading">
            <div class="spinner"></div>
            <p>Loading workflow...</p>
        </div>
        <iframe
            #workflowFrame
            [src]="iframeUrl"
            [ngStyle]="{
                'height': isFullscreen ? '100vh' : '700px'
            }"
            width="100%"
            (load)="onIframeLoad()"
            title="Workflow Editor"
        >
        </iframe>
    </div>
</div>