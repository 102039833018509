<div class="card">
  <app-placeholder-loader *ngIf="!fetchingCompleted"></app-placeholder-loader>

  <ng-template [ngIf]="testSuites?.content?.length">
    <div class="card__sidebar">
      <div class="sidebar__header">
        <h2 translate="test_suites.list.title"></h2>
        <div class="search-box">
          <mat-icon>search</mat-icon>
          <input
            type="search"
            placeholder="Search..."
            (input)="onSearch($event.target.value)"
          />
        </div>
        <button
          mat-flat-button
          color="primary"
          [routerLink]="['new']"
          class="button--add"
        >
          <mat-icon>create_new_folder</mat-icon>&nbsp;&nbsp; Create Folder
        </button>
      </div>
      <div class="sidebar__content">
        <mat-tree
          [dataSource]="dataSource"
          [treeControl]="treeControl"
          *ngIf="dataSource.data?.length > 0"
        >
          <!-- Parent Folder -->
          <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            [class.tree-node--selected]="node.id === nodeId"
          >
            <button
              mat-stroked-button
              class="tree-node-button"
              [style.padding-left.px]="32 + node.level * 28"
              (click)="onNodeClick(node)"
            >
              <mat-icon>
                {{ treeControl.isExpanded(node) ? "folder_open" : "folder" }}
              </mat-icon>
              {{ node.name }}
              <!-- <span class="tree-node--count">{{ node.testCasesDtoSize }}</span> -->
            </button>
            <button
              class="tree-node--prefix"
              mat-icon-button
              matTreeNodeToggle
              *ngIf="node.expandable"
              [style.left.px]="2 + node.level * 28"
            >
              <mat-icon>
                {{
                  treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
                }}
              </mat-icon>
            </button>
            <a
              class="tree-node--suffix"
              mat-icon-button
              [routerLink]="['new', node.id]"
            >
              <mat-icon>add</mat-icon>
            </a>
          </mat-tree-node>
        </mat-tree>
      </div>
    </div>
    <div class="card__content" *ngIf="nodeId || nodeId === 1">
      <div class="content__header">
        <span class="content__title">{{ nestestedDataDisplay?.name }}</span>
        <a
          mat-icon-button
          color="primary"
          [routerLink]="['/td', 'suites', nestestedDataDisplay?.id]"
        >
          <mat-icon>open_in_new</mat-icon>
        </a>
        <div style="flex: 1"></div>
        <button (click)="createCase()" mat-flat-button color="primary">
          <mat-icon>add_task</mat-icon>&nbsp;&nbsp; Create Test Case
        </button>
      </div>
      <div class="content__wrapper">
        <ul class="cases-list">
          <li
            *ngFor="
              let testCase of nestestedDataDisplay?.testCasesDto;
              let i = index
            "
          >
            <div class="case__id">
              <a [routerLink]="['/td', 'cases', testCase?.id, 'steps']">{{
                testCase.id
              }}</a>
            </div>
            <span class="case__name" [textContent]="testCase?.name"></span>
            <!-- <span class="case__status" [textContent]="'testcase.status_' + testCase?.status | translate"></span> -->
            <button
              mat-mini-fab
              color="primary"
              [style.visibility]="
                testCase.lastRunResult === 'SUCCESS' ? 'visible' : 'hidden'
              "
              (click)="suggestedTestCases(testCase, i)"
            >
              <mat-icon>add</mat-icon>
            </button>
          </li>
        </ul>
        <div class="list-container h-90 mt-5" *ngIf="false">
          <div
            class="test-cases-container"
            style="
              max-height: calc(100vh - 180px);
              overflow-y: auto;
              padding-right: 8px;
              padding-left: 16px;
            "
          >
            <div
              *ngFor="
                let testCases of nestestedDataDisplay?.testCasesDto;
                let i = index
              "
            >
              <div class="custom-scrollbar" style="display: flex">
                <a
                  class="list-view green-highlight pl-10 text-t-secondary align-items-center min-h-20"
                  style="padding: 10px 10px !important"
                >
                  <div
                    class="ts-col-60 d-flex align-items-center"
                    (click)="toggleExpand(i)"
                    style="cursor: pointer"
                  >
                    <mat-icon class="folder-icon">
                      {{ expandedIndexes[i] ? "expand_more" : "chevron_right" }}
                    </mat-icon>
                    <mat-icon class="folder-icon">description</mat-icon>
                    <div
                      class="d-flex flex-wrap text-break pl-10 ts-col-100-61"
                    >
                      <span
                        class="ts-col-100 pb-0"
                        [textContent]="testCases?.name"
                      ></span>
                    </div>
                  </div>
                </a>
                <div
                  class="generate-ai-container"
                  *ngIf="testCases.lastRunResult === 'SUCCESS'"
                >
                  <mat-icon
                    class="generateAI-btn animated-btn"
                    (click)="suggestedTestCases(testCases, i)"
                    >add_box</mat-icon
                  >

                  <!-- <mat-menu #generateMenu="matMenu"     >
                          <button
                            mat-menu-item
                            (click)="suggestedTestCases(testCases.id, i)"
                          >
                            Test Case Generate
                          </button>
                          <button
                            mat-menu-item
                            (click)="
                              suggestedTestCases(testCases.id, i, 'session')
                            "
                          >
                            Quick Test Case Generate
                          </button>
                        </mat-menu> -->
                </div>
              </div>
              <!-- Child Data -->
              <div
                *ngIf="expandedIndexes[i]"
                class="nested-test-cases nested-tree"
                style="margin-bottom: 12px"
              >
                <div
                  class="custom-scrollbar nested-test-cases tree-node"
                  style="display: flex"
                  *ngFor="let testCase of testCasesDto; let j = index"
                >
                  <a
                    class="list-view green-highlight lg-pm pl-10 text-t-secondary align-items-center min-h-20 nested-node"
                    style="
                      width: 94%;
                      padding: 10px 10px !important;
                      max-height: 52px;
                      margin-bottom: 0px !important;
                    "
                  >
                    <div class="ts-col-60 d-flex align-items-center">
                      <!-- <mat-icon class="folder-icon">
                                {{
                                  expandedIndexes[i]
                                    ? "expand_more"
                                    : "chevron_right"
                                }}
                              </mat-icon> -->
                      <mat-icon *ngIf="!loading" class="folder-icon"
                        >description</mat-icon
                      >
                      <ng-container *ngIf="loading">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 200 200"
                          style="width: 27px"
                        >
                          <path
                            fill="#6366F1"
                            stroke="#6366F1"
                            stroke-width="5"
                            transform-origin="center"
                            d="m148 84.7 13.8-8-10-17.3-13.8 8a50 50 0 0 0-27.4-15.9v-16h-20v16A50 50 0 0 0 63 67.4l-13.8-8-10 17.3 13.8 8a50 50 0 0 0 0 31.7l-13.8 8 10 17.3 13.8-8a50 50 0 0 0 27.5 15.9v16h20v-16a50 50 0 0 0 27.4-15.9l13.8 8 10-17.3-13.8-8a50 50 0 0 0 0-31.7Zm-47.5 50.8a35 35 0 1 1 0-70 35 35 0 0 1 0 70Z"
                          >
                            <animateTransform
                              type="rotate"
                              attributeName="transform"
                              calcMode="spline"
                              dur="2"
                              values="0;120"
                              keyTimes="0;1"
                              keySplines="0 0 1 1"
                              repeatCount="indefinite"
                            ></animateTransform>
                          </path>
                        </svg>
                      </ng-container>
                      <div
                        class="d-flex flex-wrap text-break pl-10 ts-col-100-61"
                      >
                        <span
                          *ngIf="testCaseCreated === testCases.id && loading"
                          style="font-size: small"
                        >
                          Generated From {{ testCases?.name }}
                        </span>
                        <span class="ts-col-100 pb-0">
                          {{ testCase.name }}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="nestestedDataDisplay?.testCasesDto === null"
          class="green-highlight"
          style="text-align: center"
        >
          <div class="empty-full-container ng-star-inserted">
            <div class="empty-full-content">
              <div class="empty-run-sm"></div>
              <div class="empty-text">No test cases in this folder.</div>
              <div
                class="d-flex justify-content-end ml-10 pb-10 ng-star-inserted"
              >
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="createCase()"
                >
                  <mat-icon>add</mat-icon>
                  Add Test Cases
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="!testSuites?.content?.length" class="h-100 w-100">
    <div *ngIf="fetchingCompleted" class="empty-full-container">
      <div class="empty-run-md"></div>
      <div
        [translate]="
          isFiltered
            ? 'message.common.search.not_found'
            : 'test_suites.list.not_created'
        "
        class="empty-text"
      ></div>
      <button
        *ngIf="!isFiltered"
        [routerLink]="['new']"
        class="theme-btn-primary"
        target="_parent"
      >
        +
        <span [translate]="'btn.create.test_suite'"></span>
      </button>
    </div>
  </div>
</div>

<app-generatemore-testcase
  *ngIf="isSidebarOpen"
  [isOpen]="isSidebarOpen"
  [testcaseDetails]="testcaseDetails"
  [testcasesuggestion]="testcasesuggestion"
  (closeEvent)="isSidebarOpen = false"
  [errorMessage]="errorMessage"
  [testcaseId]="testcaseId"
  (refreshRequested)="handleRefreshRequested('session')"
>
</app-generatemore-testcase>
<!-- <div *ngIf="loading" class="loading-overlay">
  <mat-progress-spinner
    class="search-spinner"
    mode="indeterminate"
    diameter="40"
  ></mat-progress-spinner>
</div> -->
